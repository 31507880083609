import React, { useState } from 'react';
import dayjs from 'dayjs';
import ReactDatePicker from 'react-datepicker';
import { PickerCustomHeader } from './components';
import { months } from './helper';

interface IInlineDatePickerProps {
	value?: string | null;
	onSetValue?: (value: string | null) => void;
	isRange?: boolean;
	minDate?: Date;
	maxDate?: Date;
}
export const InlineDatePicker: React.FC<IInlineDatePickerProps> = ({
	value,
	onSetValue,
	isRange,
	minDate,
	maxDate,
}) => {
	const [startDate, setStartDate] = useState<Date | null>(
		value ? new Date(value) : new Date(),
	);
	const [endDate, setEndDate] = useState<Date | null>(new Date());
	const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
	const [selectedMonth, setSelectedMonth] = useState(
		months[new Date().getMonth()],
	);

	const onChange = (date: Date) => {
		setStartDate(date);
		onSetValue?.(dayjs(date).format('YYYY-MM-DD'));
	};
	const onChangeRange = (dates: Array<Date | null>) => {
		const [start, end] = dates || [];
		setStartDate(start);
		setEndDate(end);
		onSetValue?.(
			`${dayjs(start).format('YYYY-MM-DD') || ''}, ${
				dayjs(end).format('YYYY-MM-DD') || ''
			}`,
		);
	};
	return (
		<ReactDatePicker
			renderCustomHeader={({
				date,
				decreaseMonth,
				increaseMonth,
				changeYear,
				changeMonth,
				prevMonthButtonDisabled,
				nextMonthButtonDisabled,
			}) => (
				<PickerCustomHeader
					selectedYear={selectedYear}
					selectedMonth={selectedMonth}
					changeYear={changeYear}
					changeMonth={changeMonth}
					date={date}
					decreaseMonth={decreaseMonth}
					increaseMonth={increaseMonth}
					prevMonthButtonDisabled={prevMonthButtonDisabled}
					nextMonthButtonDisabled={nextMonthButtonDisabled}
				/>
			)}
			onMonthChange={month => {
				const year = month.getFullYear();
				if (year !== selectedYear) {
					setSelectedYear(month.getFullYear());
				}
				setSelectedMonth(months[month.getMonth()]);
			}}
			selected={isRange ? undefined : startDate}
			startDate={isRange ? startDate : undefined}
			endDate={isRange ? endDate : undefined}
			onChange={event => {
				isRange
					? onChangeRange(event as Array<Date | null>)
					: onChange(event as Date);
			}}
			inline={true}
			selectsRange={!!isRange}
			minDate={minDate}
			maxDate={maxDate}
		/>
	);
};
