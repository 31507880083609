import { create } from 'zustand';
import { JumpToPresentConditionEnum } from '../pages/Messenger/types';

interface IState {
	messageAnchorId: number;
	setMessageAnchorId: (messageId: number) => void;
	jumpToPresentCondition: JumpToPresentConditionEnum;
	setJumpToPresentCondition: (
		jumpToPresentCondition: JumpToPresentConditionEnum,
	) => void;
}

export const useMessageNavigationStore = create<IState>(set => ({
	messageAnchorId: 0,
	jumpToPresentCondition: JumpToPresentConditionEnum.Hidden,
	setMessageAnchorId: messageId =>
		set(() => ({
			messageAnchorId: messageId,
		})),
	setJumpToPresentCondition: jumpToPresentCondition =>
		set(() => ({ jumpToPresentCondition })),
}));
