export enum ChannelType {
	ANNOUNCEMENT = 1,
	TEXT = 2,
}

export enum MessageType {
	Regular = 1,
	Reply = 2,
	Pin = 3,
}

export enum ChatType {
	DIRECT = 'direct',
	CHANNEL = 'channel',
}

export enum UserPresenceStatus {
	ONLINE = 1,
	OFFLINE = 2,
}

export enum MessageReactionType {
	ThumbsUp = 1,
	Eyes = 2,
	MoneyMouthFace = 3,
	OneHundred = 4,
	Biceps = 5,
	Fire = 6,
}

export enum MessageReactionActionType {
	CREATE = 1,
	EDIT = 2,
	DELETE = 3,
}

export enum JumpToPresentConditionEnum {
	Visible = 'visible',
	Hidden = 'hidden',
	Loading = 'loading',
	StartConversationJump = 'start-conversation-jump',
	ReplyMessageJump = 'reply-message-jump',
}

export enum SearchContentTypeOptionEnum {
	Link = 1,
	File = 2,
	Image = 3,
	Video = 4,
}

export enum ChannelSearchUserFilterEnum {
	Name = 1,
	Username = 2,
	Email = 3,
}

export type ReplyMessage = {
	messageId: number;
	sentBy: string;
	sentById: number;
	// conversationId: number;
	// text: string;
	// replyToId: number | null;
};

export type UserData = {
	userId: number;
	displayName: string;
	avatarStoragePath: string | null;
	about: string | null;
	isAdmin: boolean;
	isEveryoneAndHereDisabled: boolean;
	isHighlightsDisabled: boolean;
	isRoleMentionDisabled: boolean;
	isServerMuted: boolean;
	userStatus: UserPresenceStatus;
	email?: string | null;
};

export type AllFriendsListResponse = {
	count: number;
	friends: UserData[];
};

export type UserEventData = {
	UserId: number;
	DisplayName: string;
	AvatarStoragePath: string | null;
	About: string | null;
	IsAdmin: boolean;
	IsEveryoneAndHereDisabled: boolean;
	IsHighlightsDisabled: boolean;
	IsRoleMentionDisabled: boolean;
	IsServerMuted: boolean;
	UserStatus: UserPresenceStatus;
};
export type AllFriendsListEventResponse = {
	Count: number;
	Friends: UserEventData[];
};

export type FriendRequestData = {
	receiverAvatarUrl: string | null;
	receiverDisplayName: string;
	receiverUserStatus: UserPresenceStatus;
	receiverIsAdmin: boolean;
	receiverId: number;
	receiverEmail: string | null;
	requestId: number;
	senderAvatarUrl: string | null;
	senderDisplayName: string;
	senderUserStatus: UserPresenceStatus;
	senderIsAdmin: boolean;
	senderId: number;
	senderEmail: string | null;
};

export type FriendsRequestListResponse = {
	incomeFriendRequests: FriendRequestData[];
	outcomeFriendRequests: FriendRequestData[];
};

export type FriendRequestEventData = {
	ReceiverAvatarUrl: string | null;
	ReceiverDisplayName: string;
	ReceiverId: number;
	RequestId: number;
	SenderAvatarUrl: string | null;
	SenderDisplayName: string;
	SenderId: number;
};

export type FriendInRequestEventData = {
	FriendRequestId: number;
	UserId: number;
	DisplayName: string;
	AvatarUrl: string | null;
	UserStatus: UserPresenceStatus;
	IsAdmin: boolean;
	Email: string | null;
};

export type FriendsRequestListEventResponse = {
	IncomeRequests: FriendRequestData[];
	OutcomeRequests: FriendRequestData[];
};

export type NewFriendAddedEventData = {
	SenderId: number;
	UserId: number;
	IsAdmin: boolean;
	DisplayName: string;
	AvatarPath: string | null;
	UserStatus: UserPresenceStatus;
	Email: string | null;
};

// export type BlockListUserData = {
// 	userId: number;
// 	displayName: string;
// 	avatarStoragePath: string | null;
// 	about: string | null;
// 	isEveryoneAndHereDisabled: boolean;
// 	isHighlightsDisabled: boolean;
// 	isRoleMentionDisabled: boolean;
// 	isServerMuted: boolean;
// 	isAdmin: boolean;
// };

export type BlockListResponse = {
	count: number;
	users: UserData[];
};

export type ConversationEventItemData = {
	AvatarStoragePath: string | null;
	DisplayName: string;
	Id: number;
	LastMessage?: string | null;
	ReceiverId: number;
	IsAdmin: boolean;
	IsLastRead: boolean;
};

export type CreateConversationEventData = {
	AvatarStoragePath: string | null;
	ConversationId: number;
	DisplayName: string;
	InitiatorId: number;
	IsAdmin: boolean;
	UserId: number;
	IsLastRead: boolean;
	UserStatus: UserPresenceStatus;
	Email: string | null;
	Message: string | null;
	MessageId: number;
};

export type ConversationsListEventResponse = {
	Count: number;
	Conversations: ConversationEventItemData[];
};

export type ConversationLastMessage = {
	senderId: number;
	text: string | null;
	id: number | null;
};

export type ConversationItemData = {
	avatarStoragePath: string | null;
	displayName: string;
	id: number;
	isLastRead: boolean;
	receiverId: number;
	isAdmin: boolean;
	userStatus: UserPresenceStatus;
	email?: string | null;
	lastMessage: ConversationLastMessage | null;
	unreadCount: number;
};

export type ConversationsListResponse = {
	count: number;
	conversations: ConversationItemData[];
	unreadConversations: ConversationItemData[];
};

export type SearchUsersByNameData = {
	userId: number;
	displayName: string;
	nickName: string;
	avatarStoragePath: string | null;
	email?: string | null;
};

export type SearchWithPaginationPayload = {
	currentPage?: number;
	pageSize?: number;
	search?: string;
	conversationId?: number;
	channelId?: number;
	additionalOffset?: number;
};

export type UserByIdData = {
	nickName: string;
	displayName: string;
	about: string | null;
	avatarUrl: string | null;
	isBlocked: boolean;
	isBlockingMe: boolean;
	isFriend: boolean;
	isFriendRequestSent: boolean;
	isAdmin: boolean;
	userStatus: UserPresenceStatus;
	isBannedInChannel: boolean;
	email: string | null;
};

export type CreatePrivateConversationPayload = {
	UserToSend: number;
	Message: string | null;
};

export type SendPrivateMessagePayload = {
	ConversationId: number;
	Text: string | null;
	ReplyToId: number | null;
};

export type UpdatePrivateMessagePayload = {
	conversationId: number;
	privateMessageId: number;
	text: string;
};

export type DeletePrivateMessagePayload = {
	conversationId: number;
	privateMessageId: number;
};

export type MarkAsReadPrivateMessagesPayload = {
	conversationId: number;
	messageId: number;
};

export type EditPrivateMessageEventResponse = {
	ConversationId: number;
	SenderId: number;
	UpdatedMessageId: number;
	Text: string;
};

export type DeletePrivateMessageEventResponse = {
	ConversationId: number;
	DeletedMessageId: number;
};

export type MessageByIdResponse = {
	message: ConversationHistoryItem;
	sender: UserData;
};

export type ServerChannelData = {
	id: number;
	name: string;
	topic: string;
	lastPinnedMessageId: number | null;
	isLastRead: boolean;
	type: ChannelType;
	amountUnreadMessages: number;
};

export type ServerCategoryData = {
	id: number;
	name: string;
	channels: ServerChannelData[];
};

export type ChannelHistoryItem = {
	id: number;
	replyToId: number | null;
	text: string | null;
	isEdited: boolean;
	senderId: number;
	senderName: string;
	avatarUrl: string | null;
	isAdmin: boolean;
	sentAt: string;
	type: number;
};

export type ChannelHistoryResponse = {
	count: number;
	messages: ConversationHistoryItem[];
	userLastReadDateTime: string;
	unreadCount: number;
};

export type ChannelMessageByIdResponse = {
	message: ChannelHistoryItem;
	sender: UserData;
};

export type SendChannelMessagePayload = {
	ChannelId: number;
	Text: string;
	ReplyToId: number | null;
	MentionId: number[];
	Files?: File[] | null;
	Images?: File[] | null;
};

export type NewChannelPinEventData = {
	ChannelId: number;
	ChannelMessageId: number;
	PinnedMessageId: number;
	SendById: number;
	DisplayName: string;
	Type: number;
	PinAt: string;
	IsAdmin: boolean;
};

export type EditChannelMessagePayload = {
	channelId: number;
	messageId: number;
	text: string;
	mentionId: number[];
};

export type EditChannelMessageEventData = {
	ChannelId: number;
	SenderId: number;
	DisplayName: string;
	AvatarUrl: string | null;
	IsAdmin: boolean;
	EditAt: string;
	MessageId: number;
	ReplyToId: number | null;
	Text: string;
};

export type DeleteChannelMessageEventResponse = {
	ChannelId: number;
	DeletedMessageId: number;
};

export type UserChannelData = {
	userId: number;
	displayName: string;
	avatarStoragePath: string | null;
	isAdmin: boolean;
	about: string | null;
	isEveryoneAndHereDisabled?: boolean;
	isHighlightsDisabled?: boolean;
	isRoleMentionDisabled?: boolean;
	isServerMuted?: boolean;
	isBannedInChannel: boolean;
	isFriends: boolean;
	isBlocked: boolean;
	isBlockedMe: boolean;
	requestSentByMe: boolean;
	requestSentToMe: boolean;
	nickName: string;
	userStatus: UserPresenceStatus;
	email: string | null;
};

export type ChannelUsersResponse = {
	count: number;
	users: UserChannelData[];
};

export type UserPresenceItem = {
	userId: number;
	status: UserPresenceStatus;
};

export type MessageReactionItem = {
	emojiId: MessageReactionType;
	count: number;
	userReacted: boolean;
};

export type MessageAttachmentVideo = {
	id: number;
	link: string;
	storagePath: string;
	duration: number;
	fileName: string;
	extension: string;
	height: number;
	width: number;
};

export interface MessageAttachmentFile {
	id: number;
	link: string;
	storagePath: string;
	fileName: string;
	extension: string;
}

export interface MessageAttachmentImage {
	id: number;
	link: string;
	storagePath: string;
	extension: string;
	thumbnailLink: string;
	largeImageLink: string;
}

export type ConversationHistoryItem = {
	avatarUrl: string | null;
	id: number;
	replyToId: number | null;
	text: string | null;
	isEdited: boolean;
	isSeen: boolean;
	senderId: number;
	senderName: string;
	sentAt: string;
	isAdmin: boolean;
	type?: number;
	pinToId: number | null;
	isBannedInChannel?: boolean;
	isSenderDeleted: boolean;
	attachmentFiles: MessageAttachmentFile[];
	attachmentImages: MessageAttachmentImage[];
	attachmentVideos: MessageAttachmentVideo[];
	reactions: MessageReactionItem[];
};

export interface MessageAttachmentFileFromSocket {
	Id: number;
	Link: string;
	StoragePath: string;
	FileName: string;
	Extension: string;
}

export interface MessageAttachmentImageFromSocket {
	Id: number;
	Link: string;
	StoragePath: string;
	Extension: string;
	ThumbnailLink: string;
	LargeImageLink: string;
}

export interface MessageAttachmentVideoFromSocket {
	Id: number;
	Link: string;
	StoragePath: string;
	Duration: number;
	FileName: string;
	Extension: string;
	Height: number;
	Width: number;
}

export type NewChannelMessageEventData = {
	ChannelId: number;
	SenderId: number;
	DisplayName: string;
	AvatarUrl: string | null;
	IsAdmin: boolean;
	SentAt: string;
	MessageId: number;
	ReplyToId: number | null;
	Text: string | null;
	Type: number;
	PinToId: number | null;
	IsBannedInChannel: boolean;
	AttachmentFiles: MessageAttachmentFileFromSocket[];
	AttachmentImages: MessageAttachmentImageFromSocket[];
	AttachmentVideos: MessageAttachmentVideoFromSocket[];
};

export type NewMessageDataEventResponse = {
	ConversationId: number;
	SenderId: number;
	DisplayName: string;
	AvatarUrl: string | null;
	SentAt: string;
	MessageId: number;
	ReplyToId: number | null;
	Text: string;
	IsAdmin: boolean;
	Type: number;
	PinToId: number;
	AttachmentFiles: MessageAttachmentFileFromSocket[];
	AttachmentImages: MessageAttachmentImageFromSocket[];
	AttachmentVideos: MessageAttachmentVideoFromSocket[];
};

export type EditMessageDisplayData = {
	avatarImage: string;
	sentByDisplayName: string;
	text: string;
	isEdited?: boolean;
	type?: MessageType;
	// replyTo?: ConversationHistoryItem;
	replyToId?: number | null;
	id: number;
	date?: string;
	isAdmin?: boolean;
	sentById?: number;
	attachmentFiles: MessageAttachmentFile[];
	attachmentImages: MessageAttachmentImage[];
	attachmentVideos: MessageAttachmentVideo[];
	reactions?: MessageReactionItem[];
};

export type ReactionsModalListItem = {
	emojiId: number;
	reactedAt: string;
	userId: number;
	displayName: string;
	isAdmin: boolean;
	avatarUrl: string | null;
};

export type ReactionsModalListResponse = {
	count: number;
	reactionItemsInfo: ReactionsModalListItem[];
};

export type ReactionUpdateItemEvent = {
	EmojiId: number;
	Count: number;
};

export type ChannelMessageReactionMainEvent = ReactionUpdateItemEvent & {
	ChannelId: number;
	MessageId: number;
	UserId: number;
};

export type ChannelMessageReactionUpdateEvent = {
	ChannelId: number;
	MessageId: number;
	UserId: number;
	DeleteReaction: ReactionUpdateItemEvent;
	CreateReaction: ReactionUpdateItemEvent;
};

export type DirectMessageReactionMainEvent = ReactionUpdateItemEvent & {
	ConversationId: number;
	MessageId: number;
	UserId: number;
};

export type DirectMessageReactionUpdateEvent = {
	ConversationId: number;
	MessageId: number;
	UserId: number;
	DeleteReaction: ReactionUpdateItemEvent;
	CreateReaction: ReactionUpdateItemEvent;
};

export type NotificationSettingItemType = {
	directMessageNotification: boolean;
	channelMessageNotification: boolean;
	channelMentionsNotification: boolean;
	channelRepliesNotification: boolean;
	inAppNotification: boolean;
};

export type SearchSuggestionUserType = {
	id: number;
	avatarUrl: string | null;
	displayName: string;
	nickName: string;
};

export type SearchSuggestionsResponse = {
	count: number;
	users: SearchSuggestionUserType[];
};
