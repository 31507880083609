import { useRef, useEffect } from 'react';

interface UseSearchFieldProps {
	value: string;
	mainKeywords: string[];
	subKeywords: string[];
	setValue: (value: string) => void;
	onEnter: VoidFunction;
}

const getAbsoluteCaretPosition = (
	rootNode: HTMLDivElement,
	targetNode: Node,
	offset: number,
): number => {
	let position = 0;

	const traverse = (currentNode: Node): boolean => {
		if (currentNode === targetNode) {
			position += offset;
			return true;
		}

		if (currentNode.nodeType === Node.TEXT_NODE) {
			position += currentNode.textContent?.length || 0;
		}

		for (const child of Array.from(currentNode.childNodes)) {
			if (traverse(child)) {
				return true;
			}
		}
		return false;
	};

	traverse(rootNode);
	return position;
};

const highlightText = (
	inputText: string,
	firstWords: string[],
	secondWords: string[],
): string => {
	if (!firstWords.length) {
		return inputText;
	}

	// escape special characters for all words
	const escapedFirstWords = firstWords.map(word =>
		word.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'),
	);
	if (!secondWords.length) {
		const regex = new RegExp(`(${escapedFirstWords.join('|')})`, 'i');
		return inputText.replace(
			regex,
			(_, firstWord) => `<div class="search-keyword">${firstWord}</div>`,
		);
	}
	const escapedSecondWords = secondWords.map(word =>
		word.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'),
	);
	const firstPattern = `(${escapedFirstWords.join('|')})`;
	const secondPattern = `([\\s:_-]*(${escapedSecondWords.join('|')}))`;
	const regex = new RegExp(
		`^.*?${firstPattern}(?:${secondPattern})?.*$`,
		'i',
	);

	return inputText.replace(
		regex,
		(match, firstWord, _separator, secondWord) => {
			const startIndex = match.indexOf(firstWord);
			const beforeText = match.substring(0, startIndex);
			const highlightEndIndex =
				startIndex +
				firstWord.length +
				(secondWord ? secondWord.length + 1 : 0);
			const afterText = match.substring(highlightEndIndex);

			if (secondWord) {
				return `${beforeText}<div class="search-keyword">${firstWord} ${secondWord}</div>${afterText}`;
			}
			return `${beforeText}<div class="search-keyword">${firstWord}</div>${afterText}`;
		},
	);
};

export const useSearchField = ({
	value,
	setValue,
	onEnter,
	mainKeywords,
	subKeywords,
}: UseSearchFieldProps) => {
	const inputRef = useRef<HTMLDivElement | null>(null);
	const lastCaretPositionRef = useRef(0);
	const isFocusedRef = useRef(false);
	const isButtonUpdateRef = useRef(false);

	const handleInput = () => {
		if (!inputRef.current || !isFocusedRef.current) {
			return;
		}

		isButtonUpdateRef.current = false;

		const selection = window.getSelection();
		if (selection && selection?.rangeCount > 0) {
			const range = selection.getRangeAt(0);
			lastCaretPositionRef.current = getAbsoluteCaretPosition(
				inputRef.current,
				range.startContainer,
				range.startOffset,
			);
		}

		setValue(inputRef.current.innerText.trim());
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			onEnter();
		}
	};

	const handleFocus = () => {
		isFocusedRef.current = true;
	};

	const handleBlur = () => {
		if (!isButtonUpdateRef.current) {
			isFocusedRef.current = false;
		}
	};

	const handlePaste = (event: React.ClipboardEvent) => {
		event.preventDefault();
		const text = event.clipboardData.getData('text/plain');
		setValue(`${value}${text}`);
	};

	useEffect(() => {
		if (!inputRef.current) {
			return;
		}
		const oldLength = inputRef.current.innerText.length;
		const oldHTML = inputRef.current.innerHTML;
		const newHTML = highlightText(value.trim(), mainKeywords, subKeywords);

		if (Math.abs(value.length - oldLength) > 1) {
			isButtonUpdateRef.current = true;
			isFocusedRef.current = true;
		}

		// update innerHTML if content actually changed
		if (oldHTML !== newHTML) {
			inputRef.current.innerHTML = newHTML;
		}

		const selection = window.getSelection();
		if (!selection) {
			return;
		}

		// no previous caret position or length changed significantly (by click on button option)
		const isButtonUpdate =
			lastCaretPositionRef.current === 0 ||
			Math.abs(value.length - oldLength) > 1;

		if (isButtonUpdate) {
			let lastTextNode: Node | null = null;
			let lastOffset = 0;

			const findLastTextNode = (node: Node) => {
				if (node.nodeType === Node.TEXT_NODE && node.textContent) {
					lastTextNode = node;
					lastOffset = node.textContent.length;
				}
				for (const child of Array.from(node.childNodes)) {
					findLastTextNode(child);
				}
			};

			findLastTextNode(inputRef.current);

			if (lastTextNode) {
				const range = document.createRange();
				range.setStart(lastTextNode, lastOffset);
				range.collapse(true);
				selection.removeAllRanges();
				selection.addRange(range);
				lastCaretPositionRef.current = value.length;
				return;
			}
		}

		// for normal typing behaviour
		let targetPosition = isButtonUpdate
			? value.length
			: lastCaretPositionRef.current;

		if (value.length < oldLength) {
			targetPosition = Math.min(targetPosition, value.length);
		} else if (value.length > oldLength && !isButtonUpdate) {
			targetPosition = Math.min(
				targetPosition + (value.length - oldLength),
				value.length,
			);
		}

		let currentPosition = 0;
		let targetNode: Node | null = null;
		let targetOffset = 0;

		const findPosition = (node: Node): boolean => {
			if (node.nodeType === Node.TEXT_NODE) {
				const length = node.textContent?.length || 0;
				if (currentPosition + length >= targetPosition) {
					targetNode = node;
					targetOffset = targetPosition - currentPosition;
					return true;
				}
				currentPosition += length;
			} else {
				for (const child of Array.from(node.childNodes)) {
					if (findPosition(child)) {
						return true;
					}
				}
			}
			return false;
		};

		findPosition(inputRef.current);

		if (targetNode) {
			const range = document.createRange();
			range.setStart(targetNode, targetOffset);
			range.collapse(true);
			selection.removeAllRanges();
			selection.addRange(range);
		}
	}, [value, mainKeywords, subKeywords]);

	return {
		inputRef,
		handleInput,
		handleKeyDown,
		handleFocus,
		handleBlur,
		handlePaste,
	};
};
