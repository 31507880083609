import {
	SearchContentTypeOptionEnum,
	ChannelSearchUserFilterEnum,
	SearchOptionsViewEnum,
} from '../types';
import { getYearsUntilNow } from '../utils';

const yearsLabels = getYearsUntilNow(2023).map(year => year.label);
export const ChannelUserSearchPopupOptionsState: Record<
	string,
	SearchOptionsViewEnum
> = {
	['from']: SearchOptionsViewEnum.From,
	['mentions']: SearchOptionsViewEnum.Mentions,
	['has']: SearchOptionsViewEnum.Has,
	['during']: SearchOptionsViewEnum.During,
	['pinned']: SearchOptionsViewEnum.Pinned,
};
export const ChannelAdminSearchPopupOptionsState: Record<
	string,
	SearchOptionsViewEnum
> = {
	['from']: SearchOptionsViewEnum.From,
	['mentions']: SearchOptionsViewEnum.Mentions,
	['has']: SearchOptionsViewEnum.Has,
	['during']: SearchOptionsViewEnum.During,
	['pinned']: SearchOptionsViewEnum.Pinned,
	['user']: SearchOptionsViewEnum.User,
};

export const DirectSearchPopupOptionsState: Record<
	string,
	SearchOptionsViewEnum
> = {
	['from']: SearchOptionsViewEnum.From,
	['has']: SearchOptionsViewEnum.Has,
	['during']: SearchOptionsViewEnum.During,
};

export const DefaultChannelUserSearchLabelOptions = [
	'from:',
	'mentions:',
	'has:',
	'during:',
	'pinned:',
];

export const DefaultChannelAdminSearchLabelOptions = [
	...DefaultChannelUserSearchLabelOptions,
	'user:',
];

export const DefaultDirectSearchLabelOptions = ['from:', 'has:', 'during:'];

export const ChannelUserOptionsSubKeywords: Record<string, string[]> = {
	[SearchOptionsViewEnum.From]: [],
	[SearchOptionsViewEnum.Mentions]: [],
	[SearchOptionsViewEnum.Has]: ['link', 'file', 'image', 'video'],
	[SearchOptionsViewEnum.During]: [
		'january',
		'february',
		'march',
		'april',
		'may',
		'june',
		'july',
		'august',
		'september',
		'october',
		'november',
		'december',
		...yearsLabels,
	],
	[SearchOptionsViewEnum.Pinned]: ['true', 'false'],
};

export const ChannelAdminOptionsSubKeywords: Record<string, string[]> = {
	[SearchOptionsViewEnum.From]: [],
	[SearchOptionsViewEnum.Mentions]: [],
	[SearchOptionsViewEnum.Has]: ['link', 'file', 'image', 'video'],
	[SearchOptionsViewEnum.During]: [
		'january',
		'february',
		'march',
		'april',
		'may',
		'june',
		'july',
		'august',
		'september',
		'october',
		'november',
		'december',
		...yearsLabels,
	],
	[SearchOptionsViewEnum.Pinned]: ['true', 'false'],
	[SearchOptionsViewEnum.User]: ['email', 'name', 'username'],
};

export const DirectOptionsSubKeywords: Record<string, string[]> = {
	[SearchOptionsViewEnum.From]: [],
	[SearchOptionsViewEnum.Has]: ['link', 'file', 'image', 'video'],
	[SearchOptionsViewEnum.During]: [
		'january',
		'february',
		'march',
		'april',
		'may',
		'june',
		'july',
		'august',
		'september',
		'october',
		'november',
		'december',
		...yearsLabels,
	],
};

export const ChannelUserMainSearchOptions = [
	{
		label: 'user',
		value: SearchOptionsViewEnum.From,
	},
	{
		label: 'user',
		value: SearchOptionsViewEnum.Mentions,
	},
	{
		label: 'link, file, image or video',
		value: SearchOptionsViewEnum.Has,
	},
	{
		label: 'specific date',
		value: SearchOptionsViewEnum.During,
	},
	{
		label: 'true or false',
		value: SearchOptionsViewEnum.Pinned,
	},
];

export const ChannelAdminMainSearchOptions = [
	...ChannelUserMainSearchOptions,
	{
		label: 'email, name or username',
		value: SearchOptionsViewEnum.User,
	},
];

export const DirectMainSearchOptions = [
	{
		label: 'user',
		value: SearchOptionsViewEnum.From,
	},
	{
		label: 'link, file, image or video',
		value: SearchOptionsViewEnum.Has,
	},
	{
		label: 'specific date',
		value: SearchOptionsViewEnum.During,
	},
];

export const HasSubMenuSearchOptions = [
	{
		label: 'link',
		value: SearchContentTypeOptionEnum.Link,
	},
	{
		label: 'file',
		value: SearchContentTypeOptionEnum.File,
	},
	{
		label: 'image',
		value: SearchContentTypeOptionEnum.Image,
	},
	{
		label: 'video',
		value: SearchContentTypeOptionEnum.Video,
	},
];
export const PinnedSubMenuSearchOptions = [
	{
		label: 'true',
		value: true,
	},
	{
		label: 'false',
		value: false,
	},
];

export const UserSubMenuSearchOptions = [
	{
		label: 'email',
		value: ChannelSearchUserFilterEnum.Email,
	},
	{
		label: 'name',
		value: ChannelSearchUserFilterEnum.Name,
	},
	{
		label: 'username',
		value: ChannelSearchUserFilterEnum.Username,
	},
];

export const MonthsInDateSection = [
	{
		label: 'january',
		value: 0,
	},
	{
		label: 'february',
		value: 1,
	},
	{
		label: 'march',
		value: 2,
	},
	{
		label: 'april',
		value: 3,
	},
	{
		label: 'may',
		value: 4,
	},
	{
		label: 'june',
		value: 5,
	},
	{
		label: 'july',
		value: 6,
	},
	{
		label: 'august',
		value: 7,
	},
	{
		label: 'september',
		value: 8,
	},
	{
		label: 'october',
		value: 9,
	},
	{
		label: 'november',
		value: 10,
	},
	{
		label: 'december',
		value: 11,
	},
];
