/* eslint-disable indent */
// /* eslint-disable indent */
// /* eslint-disable no-mixed-spaces-and-tabs */
import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useChannel } from 'ably/react';
import { useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { Loader } from '../../../../components';
import { DateDivider, MessageWithAddons } from '../../messages-components';
import {
	DeleteMessageConfirmModal,
	PinMessageConfirmModal,
} from '../../modals';
import {
	ChatType,
	EditMessageDisplayData,
	JumpToPresentConditionEnum,
	MessageReactionType,
	ReplyMessage,
	ServerChannelData,
} from '../../types';
import { StartOfServerChat } from './StartOfServerChat';
import {
	useChatUserStore,
	useMessageNavigationStore,
	useSuspendModalStore,
} from '../../../../store';
import { extractMentionsIds } from '../../utils';
import {
	useCreateChannelMessageReaction,
	useDeleteChannelMessageReaction,
	useDeleteUserChannelMessageByAdmin,
	useFindChannelMessage,
	useGetChannelMessages,
	usePinMessage,
	useUpdateChannelMessageReaction,
	useUpdateChannelsBanStatus,
} from '../../queries';
import { deleteChannelMessage, editChannelMessage } from '../../helpers';
import { DEFAULT_LAST_READ_TIME, SOCKET_EVENTS } from '../../constants';
import { useUpdateReactionsList } from '../../hooks';
import { MessengerQueryKeys } from '../../queries/query-keys';
import { useHandleServerMessagesSocketEvents } from '../hooks';
import { filterAblyErrorsToSentry, wait } from '../../../../utils';

interface IServerMessagesListProps {
	id?: string;
	onSetReplyMessage: (obj: ReplyMessage) => void;
	replyMessage: ReplyMessage | null;
	channelData?: ServerChannelData | null;
	lastReadTime?: string;
	disableMenu?: boolean;
	updateLastReadTime: (time: string) => void;
	updateUnreadCount: Dispatch<SetStateAction<number>>;
	onMarkAsRead: (id: number) => Promise<void>;
	setFindLoading: (value: boolean) => void;
}

export const ServerMessagesListTest2: React.FC<IServerMessagesListProps> = ({
	id,
	onSetReplyMessage,
	channelData,
	lastReadTime,
	replyMessage,
	disableMenu,
	updateLastReadTime,
	updateUnreadCount,
	onMarkAsRead,
	setFindLoading,
}) => {
	const [messageToDelete, setMessageToDelete] =
		useState<EditMessageDisplayData | null>(null);
	const [messageToPin, setMessageToPin] =
		useState<EditMessageDisplayData | null>(null);
	const [highlightedId, setHighlightedId] = useState<number | null>(null);

	const { onOpen } = useSuspendModalStore();
	const { user } = useChatUserStore();
	const {
		jumpToPresentCondition,
		setJumpToPresentCondition,
		messageAnchorId,
		setMessageAnchorId,
	} = useMessageNavigationStore();
	const { mutate } = useUpdateChannelsBanStatus();
	const { pinMessage } = usePinMessage();
	const { mutateAsync: createChannelMessageReaction } =
		useCreateChannelMessageReaction();
	const { mutateAsync: updateChannelMessageReaction } =
		useUpdateChannelMessageReaction();
	const { mutateAsync: removeChannelMessageReaction } =
		useDeleteChannelMessageReaction();
	const { mutateAsync: deleteMessageByAdmin } =
		useDeleteUserChannelMessageByAdmin();
	const { mutate: updateMessageReactionsList } = useUpdateReactionsList(
		MessengerQueryKeys.GET_CHANNEL_MESSAGE_REACTIONS_PREVIEW,
	);

	const queryClient = useQueryClient();

	const shouldCallMarkAsReadRef = useRef(true);

	const bottomInView = useInView(); // scroll down
	const topInView = useInView(); // scroll up
	const parentRef = useRef<HTMLDivElement>(null);
	const scrollRef = useRef<HTMLDivElement | null>(null);
	const currentFetchDirectionRef = useRef('next');
	const isJumpingFromDeepRef = useRef(false);

	const {
		newMessages,
		handleServerMessageSocketEvent,
		setHistoryMessages,
		historyMessages,
		setNewMessages,
	} = useHandleServerMessagesSocketEvents({
		id,
		updateUnreadCount,
		updateLastReadTime,
		scrollRef,
		shouldCallMarkAsReadRef,
	});

	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		isFetching,
		hasPreviousPage,
		isFetchingPreviousPage,
		fetchPreviousPage,
	} = useGetChannelMessages(id ? +id : null);

	useChannel(
		{
			channelName: `private-channel:${id}`,
			onChannelError: err => {
				filterAblyErrorsToSentry(err);
			},
			onConnectionError: err => {
				console.log(err, 'private channel connection err');
				filterAblyErrorsToSentry(err);
			},
		},
		message => {
			console.log(hasPreviousPage, message, 'server message');
			if (
				message.name === SOCKET_EVENTS.CHANNEL_MESSAGE_EVENT &&
				hasPreviousPage
			) {
				return;
			}

			handleServerMessageSocketEvent(message);
		},
	);

	// console.log({ hasNextPage, hasPreviousPage, data, newMessages });

	const handleViewPositionOnScrollDown = (
		isFetchingPreviousPage: boolean,
	) => {
		const parentContainer = parentRef.current;

		if (
			isFetchingPreviousPage ||
			!parentContainer ||
			currentFetchDirectionRef.current !== 'prev'
		) {
			return;
		}

		parentRef.current.scrollTo({
			top: -1,
			behavior: 'smooth',
		});
	};

	const combinedMessages = useMemo(
		() => [...newMessages, ...historyMessages],
		[newMessages, historyMessages],
	);

	useEffect(() => {
		if (bottomInView.inView && !isFetchingPreviousPage && hasPreviousPage) {
			currentFetchDirectionRef.current = 'prev';
			fetchPreviousPage();
		}
	}, [bottomInView.inView]);

	useEffect(() => {
		if (topInView.inView && !isFetchingNextPage && hasNextPage) {
			currentFetchDirectionRef.current = 'next';
			fetchNextPage();
		}
	}, [topInView.inView]);

	useEffect(() => {
		if (id && data?.pages.length && !isFetching) {
			const flatMessages =
				data?.pages?.flatMap(page => page?.messages || []) || [];
			// console.log('setting history messages', flatMessages, data);
			setHistoryMessages(flatMessages);
			handleViewPositionOnScrollDown(isFetchingPreviousPage);
			updateLastReadTime(
				data?.pages?.[data?.pages.length - 1].userLastReadDateTime ||
					DEFAULT_LAST_READ_TIME,
			);
			updateUnreadCount(
				data?.pages?.[data?.pages.length - 1].unreadCount || 0,
			);

			if (parentRef.current && isJumpingFromDeepRef.current) {
				setJumpToPresentCondition(JumpToPresentConditionEnum.Hidden);
				parentRef.current.scrollTo({
					top: -1,
					behavior: 'smooth',
				});
				isJumpingFromDeepRef.current = false;
			}
		}
	}, [data, id]);

	useEffect(() => {
		return () => {
			if (user?.userId && id && shouldCallMarkAsReadRef.current) {
				onMarkAsRead(+id);
			}
		};
	}, [id, user]);

	const onEditMessage = async (messageId: number, newText: string) => {
		try {
			if (!channelData) {
				return;
			}
			const mentionsIds = extractMentionsIds(newText);

			await editChannelMessage({
				text: newText,
				messageId,
				mentionId: mentionsIds || [],
				channelId: channelData.id,
			});
		} catch (error) {
			console.error('Error editing message: ', error);
		}
	};

	const onDeleteMessage = async (messageId: number, byAdmin?: boolean) => {
		try {
			if (!channelData) {
				return;
			}
			if (byAdmin) {
				await deleteMessageByAdmin(messageId);
				return;
			}
			await deleteChannelMessage(messageId);
		} catch (error) {
			console.error('Error deleting message: ', error);
		}
	};

	const onPinMessage = async (messageId: number) => {
		try {
			if (!channelData || !id) {
				return;
			}

			const res = await pinMessage(messageId, +id);
			if (!res.success) {
				return;
			}
			await onMarkAsRead(+id);
		} catch (error) {
			console.error('Error pinning message: ', error);
		}
	};

	const onSuspendUser = (userId: number) => {
		onOpen(userId, false);
	};

	const unSuspendUser = (userId: number) => {
		mutate({ userId, isBanned: false, deleteMessage: false });
	};

	const onSuspendAndPurgeMessagesUser = (userId: number) => {
		onOpen(userId, true);
	};

	const onReact = async (
		messageId: number,
		emojiId: MessageReactionType,
		reactedEmojiId?: number | null,
	) => {
		try {
			if (!reactedEmojiId) {
				const res = await createChannelMessageReaction({
					channelMessageId: messageId,
					reactionEmojiId: emojiId,
				});
				if (!res.success) {
					return;
				}
				updateMessageReactionsList(emojiId, messageId, 'add');
				return;
			}
			if (emojiId === reactedEmojiId) {
				const res = await removeChannelMessageReaction(messageId);
				if (!res.success) {
					return;
				}
				updateMessageReactionsList(emojiId, messageId, 'remove');
				return;
			}
			await updateChannelMessageReaction({
				channelMessageId: messageId,
				reactionEmojiId: emojiId,
			});
		} catch (error) {
			console.log('onReact error: ', error);
		}
	};

	const [searchParams, setSearchParams] = useSearchParams();

	const targetMessageId = searchParams.get('targetMessageId');

	const {
		data: findData,
		// , isLoading: findLoading
	} = useFindChannelMessage(targetMessageId, +(id || 0));

	useEffect(() => {
		if (findData?.value?.messages?.length) {
			// console.log('first data should replace chat');
			queryClient.setQueryData(
				[MessengerQueryKeys.GET_CHANNEL_MESSAGES, +(id || 0)],
				oldData => {
					// console.log(oldData, id, 'oldData');
					const reversedMessages = [
						...findData.value.messages,
					].reverse();

					currentFetchDirectionRef.current = 'center';

					return {
						pages: [
							{
								...findData.value,
								messages: reversedMessages,
								lastMessageId: reversedMessages.at(-1)?.id,
								firstMessageId: reversedMessages.at(0)?.id,
								direction: 'center',
							},
						],

						pageParams: [
							{
								direction: 'center',
								value: targetMessageId
									? +targetMessageId
									: null,
							},
						],
					};
				},
			);
			setNewMessages([]);
		}
	}, [findData, queryClient]);

	const onNavigateToMessage = async (messageId: number) => {
		const possibleMessageIndex = combinedMessages.findIndex(
			elem => elem.id === messageId,
		);
		if (possibleMessageIndex > -1) {
			const container = parentRef.current;
			const item = container?.children[possibleMessageIndex];
			if (item) {
				const itemYPosition = item.getBoundingClientRect().y;
				item.scrollIntoView({
					behavior:
						itemYPosition >= -1000 && itemYPosition <= 1000
							? 'smooth'
							: 'auto',
					block: 'center',
				});
				await wait(200);
				setHighlightedId(messageId);
				await wait(1000);
				setHighlightedId(null);
			}
			return;
		}

		const newSearchParams = new URLSearchParams(searchParams);
		newSearchParams.set('targetMessageId', messageId.toString());
		setSearchParams(newSearchParams);
		setFindLoading(true);
		setJumpToPresentCondition(JumpToPresentConditionEnum.ReplyMessageJump);

		// console.log(messageId, 'reply message id');
	};

	const onNavigateToLastChannelMessage = useCallback(async () => {
		if (!parentRef.current) {
			return;
		}

		setJumpToPresentCondition(JumpToPresentConditionEnum.Loading);
		if (!hasPreviousPage) {
			parentRef.current.scrollTo({
				top: -500,
			});
			parentRef.current.scrollTo({
				top: -1,
				behavior: 'smooth',
			});
			setJumpToPresentCondition(JumpToPresentConditionEnum.Hidden);
			return;
		}
		if (!id) {
			return;
		}
		queryClient.resetQueries({
			queryKey: [MessengerQueryKeys.GET_CHANNEL_MESSAGES, +id],
		});
		currentFetchDirectionRef.current = 'next';
		isJumpingFromDeepRef.current = true;
		setNewMessages([]);
		setHistoryMessages([]);
	}, [hasPreviousPage]);

	const loadAllAttachments = async () => {
		const imageElements = Array.from(
			document.querySelectorAll('.attachment-image'),
		);

		const imageLoadPromises = imageElements.map(img => {
			// @ts-ignore
			if (img.complete) {
				return Promise.resolve();
			}
			return new Promise(resolve => {
				// @ts-ignore
				img.onload = img.onerror = resolve;
			});
		});

		await Promise.all(imageLoadPromises);
	};

	const onScrollContainer = useCallback(() => {
		const currentScrollPosition = parentRef.current?.scrollTop || 0;
		const { Hidden, Visible, Loading } = JumpToPresentConditionEnum;

		const isInCenterFetch = currentFetchDirectionRef.current === 'center';
		const isScrolledNearTop =
			currentScrollPosition >= -1500 && currentScrollPosition < 0;
		const isVisibleCondition =
			jumpToPresentCondition === Visible ||
			jumpToPresentCondition === Loading;
		const isReplying = !!replyMessage;

		if (
			isInCenterFetch &&
			!isReplying &&
			jumpToPresentCondition === Hidden
		) {
			setJumpToPresentCondition(Visible);
			return;
		}

		if (
			isScrolledNearTop &&
			!hasPreviousPage &&
			jumpToPresentCondition !== Hidden
		) {
			setJumpToPresentCondition(Hidden);
			return;
		}

		if (
			isInCenterFetch ||
			currentScrollPosition > -1500 ||
			isVisibleCondition ||
			isReplying
		) {
			return;
		}

		setJumpToPresentCondition(Visible);
	}, [jumpToPresentCondition, replyMessage, hasPreviousPage]);

	const highlightMessage = async (messageId: number) => {
		const index = combinedMessages.findIndex(msg => msg.id === messageId);
		console.log(index, 'index in high');
		if (index !== -1) {
			const container = parentRef.current;
			const item = container?.children[index + 2];

			if (item) {
				await loadAllAttachments();
				setFindLoading(false);
				item.scrollIntoView({ block: 'center' });
			}
			setSearchParams({});
			await wait(200);
			setHighlightedId(messageId);
			if (!replyMessage) {
				setJumpToPresentCondition(JumpToPresentConditionEnum.Visible);
			}
			await wait(1000);
			setHighlightedId(null);
		}
	};

	useEffect(() => {
		if (targetMessageId) {
			highlightMessage(+targetMessageId!);
		}
	}, [targetMessageId, combinedMessages]);

	useEffect(() => {
		if (
			jumpToPresentCondition ===
			JumpToPresentConditionEnum.StartConversationJump
		) {
			onNavigateToLastChannelMessage();
		}
	}, [jumpToPresentCondition]);

	useEffect(() => {
		if (messageAnchorId) {
			onNavigateToMessage(messageAnchorId);
			setMessageAnchorId(0);
		}
	}, [messageAnchorId]);

	const setRefs = useCallback(
		(node: HTMLDivElement) => {
			// Ref's from useRef needs to have the node assigned to `current`
			scrollRef.current = node;
			// Callback refs, like the one from `useInView`, is a function that takes the node as an argument
			bottomInView.ref(node);
		},
		[bottomInView],
	);

	const getContainerHeight = () => {
		const offset =
			jumpToPresentCondition === JumpToPresentConditionEnum.Visible ||
			jumpToPresentCondition === JumpToPresentConditionEnum.Loading ||
			replyMessage
				? 230
				: 160;

		return `calc(100vh - ${offset}px)`;
	};

	return (
		<>
			<Flex
				ref={parentRef}
				flexDirection="column-reverse"
				position="relative"
				h={getContainerHeight()}
				transition="height .2s ease"
				overflowY="auto"
				onScroll={onScrollContainer}>
				{isFetching &&
				!isFetchingNextPage &&
				!isFetchingPreviousPage ? (
					<Loader centerHeight="100%" />
				) : (
					<>
						<Box
							// border="1px solid red"
							// ref={bottomInView.ref}
							ref={setRefs}
						/>
						{isFetchingPreviousPage ? (
							<Loader centerHeight="80px" spinnerSize="sm" />
						) : null}
						{combinedMessages.map((elem, index) => (
							<div
								key={elem.id}
								className={
									elem?.id === highlightedId
										? 'highlight'
										: ''
								}>
								<MessageWithAddons
									messageType={elem.type}
									arr={combinedMessages}
									data={elem}
									index={index}
									onEditMessage={onEditMessage}
									onSetDeleteMessage={setMessageToDelete}
									onSetReplyMessage={onSetReplyMessage}
									onSetPinMessage={setMessageToPin}
									allowFullAccess={
										user?.userId === elem.senderId ||
										user?.isAdmin
									}
									includeEdit={user?.userId === elem.senderId}
									lastReadTime={
										lastReadTime === DEFAULT_LAST_READ_TIME
											? undefined
											: lastReadTime
									}
									includePin={user?.isAdmin}
									hideNewDividerForNewUser={true}
									enableMentions={true}
									disableMenu={disableMenu}
									onSuspendUser={onSuspendUser}
									onSuspendAndPurgeMessagesUser={
										onSuspendAndPurgeMessagesUser
									}
									unSuspendUser={unSuspendUser}
									chatType={ChatType.CHANNEL}
									onReact={onReact}
									includeUserInfoPopup={true}
									onReplyPress={onNavigateToMessage}
									isReplyMessageJumpEnabled={true}
									isChatListFlow={true}
								/>
							</div>
						))}
						{combinedMessages?.length ? (
							<DateDivider
								date={
									new Date(
										combinedMessages[
											combinedMessages.length - 1
										].sentAt,
									)
								}
							/>
						) : null}
						{isFetchingNextPage ? (
							<Loader centerHeight="80px" spinnerSize="sm" />
						) : null}
						<Box
							// border="1px solid blue"
							ref={topInView.ref}
						/>
						<StartOfServerChat channelData={channelData} />
					</>
				)}
			</Flex>
			<DeleteMessageConfirmModal
				isOpen={!!messageToDelete}
				onClose={() => setMessageToDelete(null)}
				messageToDelete={messageToDelete}
				onDeleteMessage={onDeleteMessage}
				chatType={ChatType.CHANNEL}
				currentUserId={user?.userId}
			/>
			<PinMessageConfirmModal
				isOpen={!!messageToPin}
				onClose={() => setMessageToPin(null)}
				message={messageToPin}
				action={onPinMessage}
				variant="add"
				chatType={ChatType.CHANNEL}
			/>
		</>
	);

	// return (
	// 	<div
	// 		style={{
	// 			display: 'flex',
	// 			flexDirection: 'column-reverse',
	// 			flex: 1,
	// 			height: 0,
	// 		}}>
	// 		<button
	// 			onClick={() => {
	// 				virtualizer.scrollToIndex(0);
	// 			}}>
	// 			scroll to the top
	// 		</button>
	// 		<span style={{ padding: '0 4px' }} />
	// 		<button
	// 			onClick={() => {
	// 				virtualizer.scrollToIndex(count / 2);
	// 			}}>
	// 			scroll to the middle
	// 		</button>
	// 		<span style={{ padding: '0 4px' }} />
	// 		<button
	// 			onClick={() => {
	// 				virtualizer.scrollToIndex(count - 1);
	// 			}}>
	// 			scroll to the end
	// 		</button>
	// 		<hr />
	// 		<div
	// 			ref={parentRef}
	// 			className="List"
	// 			style={{
	// 				height: `calc(100vh - ${replyMessage ? 230 : 160}px)`,
	// 				width: '100%',
	// 				overflowY: 'auto',
	// 				contain: 'strict',
	// 			}}>
	// 			<div
	// 				style={{
	// 					height: virtualizer.getTotalSize(),
	// 					width: '100%',
	// 					position: 'relative',
	// 				}}>
	// 				<div
	// 					style={{
	// 						position: 'absolute',
	// 						top: 0,
	// 						left: 0,
	// 						width: '100%',
	// 						transform: `translateY(${items[0]?.start ?? 0}px)`,
	// 					}}>
	// 					{items.map(virtualRow => {
	// 						const msg = combinedMessages[virtualRow.index];
	// 						return (
	// 							<div
	// 								key={virtualRow.key}
	// 								data-index={virtualRow.index}
	// 								ref={virtualizer.measureElement}>
	// 								<MessageWithAddons
	// 									// key={item.key}
	// 									messageType={msg.type}
	// 									arr={combinedMessages}
	// 									data={msg}
	// 									index={virtualRow.index}
	// 									onEditMessage={onEditMessage}
	// 									onSetDeleteMessage={setMessageToDelete}
	// 									onSetReplyMessage={onSetReplyMessage}
	// 									onSetPinMessage={setMessageToPin}
	// 									allowFullAccess={
	// 										user?.userId === msg.senderId ||
	// 										user?.isAdmin
	// 									}
	// 									includeEdit={
	// 										user?.userId === msg.senderId
	// 									}
	// 									lastReadTime={
	// 										lastReadTime ===
	// 										DEFAULT_LAST_READ_TIME
	// 											? undefined
	// 											: lastReadTime
	// 									}
	// 									includePin={user?.isAdmin}
	// 									hideNewDividerForNewUser={true}
	// 									enableMentions={true}
	// 									disableMenu={disableMenu}
	// 									onSuspendUser={onSuspendUser}
	// 									onSuspendAndPurgeMessagesUser={
	// 										onSuspendAndPurgeMessagesUser
	// 									}
	// 									unSuspendUser={unSuspendUser}
	// 									chatType={ChatType.CHANNEL}
	// 									onReact={onReact}
	// 									includeUserInfoPopup={true}
	// 									onReplyPress={onReplyPress}
	// 								/>
	// 							</div>
	// 						);
	// 					})}
	// 				</div>
	// 			</div>
	// 		</div>
	// 	</div>
	// );

	// <div>
	{
		/* {status === 'loading' ? (
				<p>Loading...</p>
			) : status === 'error' ? (
				<span>Error</span>
			) : (
				<>
					<div>
						<button
							ref={beforeInView.ref}
							onClick={() => fetchPreviousPage()}
							disabled={
								!hasPreviousPage || isFetchingPreviousPage
							}>
							{isFetchingPreviousPage
								? 'Loading more...'
								: hasPreviousPage
								? 'Load Older'
								: 'Nothing more to load'}
						</button>
					</div>

					<div
						ref={parentRef}
						style={{
							// height: `${virtualizer.getTotalSize()}px`,
							width: '100%',
							position: 'relative',
							overflow: 'auto',
							height: `calc(100vh - ${
								replyMessage ? 230 : 160
							}px)`,
						}}>
						{virtualizer.getVirtualItems().map(item => {
							const msg = combinedMessages[item.index];
							return (
								<div
									key={item.key}
									data-index={item.index}
									ref={virtualizer.measureElement}
									style={{
										position: 'absolute',
										top: 0,
										left: 0,
										width: '100%',
										transform: `translateY(${
											item.start -
											virtualizer.options.scrollMargin
										}px)`,
									}}>
									<MessageWithAddons
										// key={item.key}
										messageType={msg.type}
										arr={combinedMessages}
										data={msg}
										index={item.index}
										onEditMessage={onEditMessage}
										onSetDeleteMessage={setMessageToDelete}
										onSetReplyMessage={onSetReplyMessage}
										onSetPinMessage={setMessageToPin}
										allowFullAccess={
											user?.userId === msg.senderId ||
											user?.isAdmin
										}
										includeEdit={
											user?.userId === msg.senderId
										}
										lastReadTime={
											lastReadTime ===
											DEFAULT_LAST_READ_TIME
												? undefined
												: lastReadTime
										}
										includePin={user?.isAdmin}
										hideNewDividerForNewUser={true}
										enableMentions={true}
										disableMenu={disableMenu}
										onSuspendUser={onSuspendUser}
										onSuspendAndPurgeMessagesUser={
											onSuspendAndPurgeMessagesUser
										}
										unSuspendUser={unSuspendUser}
										chatType={ChatType.CHANNEL}
										onReact={onReact}
										includeUserInfoPopup={true}
										onReplyPress={onReplyPress}
									/>
								</div>
							);
						})}
					</div>

					<div>
						<button
							ref={afterInView.ref}
							onClick={() => fetchNextPage()}
							disabled={!hasNextPage || isFetchingNextPage}>
							{isFetchingNextPage
								? 'Loading more...'
								: hasNextPage
								? 'Load Newer'
								: 'Nothing more to load'}
						</button>
					</div>
					<div>
						{isFetching && !isFetchingNextPage
							? 'Background Updating...'
							: null}
					</div>
				</>
			)} */
	}
	{
		/* <InfiniteScroll
				dataLength={combinedMessages.length}
				refreshFunction={fetchNextPage}
				next={fetchNextPage}
				hasMore={!!hasNextPage}
				height={`calc(100vh - ${replyMessage ? 230 : 160}px)`}
				scrollThreshold={500}
				loader={null}
				inverse={true}
				style={{
					paddingTop: '40px',
					display: 'flex',
					flexDirection: 'column-reverse',
				}}>
				{isFetching && !isFetchingNextPage ? (
					<Loader centerHeight="100%" />
				) : (
					<>
						<span ref={scrollRef}></span>
						{combinedMessages?.map((elem, index, arr) => (
							<MessageWithAddons
								key={elem.id}
								messageType={elem.type}
								arr={arr}
								data={elem}
								index={index}
								onEditMessage={onEditMessage}
								onSetDeleteMessage={setMessageToDelete}
								onSetReplyMessage={onSetReplyMessage}
								onSetPinMessage={setMessageToPin}
								allowFullAccess={
									user?.userId === elem.senderId ||
									user?.isAdmin
								}
								includeEdit={user?.userId === elem.senderId}
								lastReadTime={
									lastReadTime === DEFAULT_LAST_READ_TIME
										? undefined
										: lastReadTime
								}
								includePin={user?.isAdmin}
								hideNewDividerForNewUser={true}
								enableMentions={true}
								disableMenu={disableMenu}
								onSuspendUser={onSuspendUser}
								onSuspendAndPurgeMessagesUser={
									onSuspendAndPurgeMessagesUser
								}
								unSuspendUser={unSuspendUser}
								chatType={ChatType.CHANNEL}
								onReact={onReact}
								includeUserInfoPopup={true}
								onReplyPress={onReplyPress}
							/>
						))}
						{combinedMessages?.length ? (
							<DateDivider
								date={
									new Date(
										combinedMessages[
											combinedMessages.length - 1
										].sentAt,
									)
								}
							/>
						) : null}
						{isFetchingNextPage ? (
							<Loader centerHeight="80px" spinnerSize="sm" />
						) : null}
						<span ref={topScrollRef}></span>
						<StartOfServerChat channelData={channelData} />
					</>
				)}
			</InfiniteScroll> */
	}
};
