export enum MessengerQueryKeys {
	GET_CHANNEL_ONLINE_USERS = 'get-channel-online-users',
	GET_ALL_CHANNEL_USERS = 'get-all-channel-users',
	GET_SERVER_CATEGORIES = 'get-server-categories',
	GET_BLOCK_LIST_USERS = 'get-block-list-users',
	GET_CHANNEL_BY_ID = 'get-channel-by-id',
	GET_CHANNEL_MESSAGE_BY_ID = 'get-channel-message-by-id',
	GET_CHANNEL_MESSAGE_REACTIONS = 'get-channel-message-reactions',
	GET_CHANNEL_MESSAGE_REACTIONS_PREVIEW = 'get-channel-message-reactions-preview',
	GET_CHANNEL_MESSAGES = 'get-channel-messages',
	GET_CHANNEL_PINNED_MESSAGES = 'get-channel-pinned-messages',
	GET_DIRECT_MESSAGE_REACTIONS = 'get-direct-message-reactions',
	GET_DIRECT_MESSAGES = 'get-direct-messages',
	GET_USER_FRIENDS = 'get-user-friends',
	GET_ONLINE_FRIENDS = 'get-online-friends',
	GET_FRIENDS_REQUESTS = 'get-friends-requests',
	GET_PRIVATE_MESSAGE_BY_ID = 'get-private-message-by-id',
	GET_USER_BY_ID = 'get-user-by-id',
	GET_CHAT_USER = 'get-chat-user',
	GET_NOTIFICATION_SETTING = 'get-notification-setting',
	FIND_CHANNEL_MESSAGE = 'find-channel-message',
	FIND_CONVERSATION_MESSAGE = 'find-conversation-message',
	CHANNEL_MESSAGES_BY_SEARCH = 'channel-messages-by-search',
	CHANNEL_SEARCH_SUGGESTIONS = 'channel-search-suggestions',
	DIRECT_MESSAGES_BY_SEARCH = 'direct-messages-by-search',

	//MUTATION KEYS
	CHECK_CONVERSATION_EXIST = 'check-conversation-exist',
	CREATE_CHANNEL_MESSAGE_REACTION = 'create-channel-message-reaction',
	SEND_CHANNEL_MESSAGE = 'send-channel-message',
	CREATE_DIRECT_CONVERSATION = 'create-direct-conversation',
	SEND_DIRECT_MESSAGE = 'send-direct-message',
	CREATE_DIRECT_MESSAGE_REACTION = 'create-direct-message-reaction',
	DELETE_CATEGORY = 'delete-category',
	DELETE_CHANNEL = 'delete-channel',
	DELETE_CHANNEL_MESSAGE_REACTION = 'delete-channel-message-reaction',
	DELETE_DIRECT_MESSAGE_REACTION = 'delete-direct-message-reaction',
	DELETE_CHANNEL_MESSAGE_BY_ADMIN = 'delete-channel-message-by-admin',
	EDIT_CATEGORY = 'edit-category',
	EDIT_CHANNEL = 'edit-channel',
	MARK_AS_READ_CHANNEL = 'mark-as-read-channel',
	MARK_AS_READ_CONVERSATION = 'mark-as-read-conversation',
	UN_PIN_ALL_CHANNEL_MESSAGES = 'un-pin-all-channel-message',
	UN_PIN_CHANNEL_MESSAGE = 'un-pin-channel-message',
	UPDATE_CHANNEL_MESSAGE_REACTION = 'update-channel-message-reaction',
	UPDATE_DIRECT_MESSAGE_REACTION = 'update-direct-message-reaction',
	SAVE_DEVICE_TOKEN = 'save-device-token',
	DELETE_DEVICE_TOKEN = 'delete-device-token',
}
