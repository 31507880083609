import React, { useEffect } from 'react';
import { Flex, IconButton } from '@chakra-ui/react';
import { PinnedMessagesPopup } from './PinnedMessagesPopup/PinnedMessagesPopup';
import {
	useMessengerSidebarsOpenStore,
	useDefaultUserStore,
} from '../../../../store';
import { UserCircleIcon } from '../../../../assets';
import {
	ChannelAdminMainSearchOptions,
	ChannelAdminOptionsSubKeywords,
	ChannelAdminSearchPopupOptionsState,
	DefaultChannelAdminSearchLabelOptions,
	DefaultChannelUserSearchLabelOptions,
	ChannelUserMainSearchOptions,
	ChannelUserSearchPopupOptionsState,
	ChannelUserOptionsSubKeywords,
} from '../../constants';
import { SearchPopup } from '../../common-components';
import { ChatType } from '../../types';

interface IChannelHeader {
	id: number;
}

export const ChannelHeader: React.FC<IChannelHeader> = ({ id }) => {
	const { isAdmin } = useDefaultUserStore();

	const { onToggleMembersSidebar, onCloseSearchResultSidebar } =
		useMessengerSidebarsOpenStore();

	useEffect(() => {
		return () => {
			onCloseSearchResultSidebar(true);
		};
	}, [id]);

	return (
		<Flex
			width="100%"
			gap="10px"
			justify="flex-end"
			align="center"
			bg="mainBg"
			p="10px"
			position="relative"
			zIndex={21}
			boxShadow="0px 1px 20px #0F1B491A">
			<PinnedMessagesPopup />
			{/* <IconButton aria-label="Notifications" bg="white">
						<BellIcon />
					</IconButton>
					<IconButton aria-label="Notifications" bg="white">
						<UserPlusIcon />
					</IconButton> */}
			{/* {isAdmin ? (
				<> */}
			<IconButton
				aria-label="Channel-users-sidebar-toggle"
				bg="white"
				onClick={onToggleMembersSidebar}>
				<UserCircleIcon />
			</IconButton>

			<SearchPopup
				key={id}
				defaultMainKeywords={
					isAdmin
						? DefaultChannelAdminSearchLabelOptions
						: DefaultChannelUserSearchLabelOptions
				}
				searchPopupOptionsState={
					isAdmin
						? ChannelAdminSearchPopupOptionsState
						: ChannelUserSearchPopupOptionsState
				}
				mainSearchOptionsMenu={
					isAdmin
						? ChannelAdminMainSearchOptions
						: ChannelUserMainSearchOptions
				}
				defaultSubKeywords={
					isAdmin
						? ChannelAdminOptionsSubKeywords
						: ChannelUserOptionsSubKeywords
				}
				chatType={ChatType.CHANNEL}
			/>
		</Flex>
	);
};
