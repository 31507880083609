/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */

import React, { useMemo, useState, useRef, useCallback } from 'react';
import {
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	VStack,
	Divider,
	Box,
	useOutsideClick,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { InlineDatePicker } from '../../../../components';
import { useDynamicDebounce } from '../../../../hooks';
import { useChannelSearchStore } from '../../../../store';
import { useMessengerSidebarsOpenStore } from '../../../../store/useMessengerSidebarsOpenStore';
import { useGetChannelSearchSuggestions } from '../../queries';
import {
	HasSubMenuSearchOptions,
	MonthsInDateSection,
	PinnedSubMenuSearchOptions,
	UserSubMenuSearchOptions,
} from '../../constants';
import { getISODateRange, getYearsUntilNow, isValidDate } from '../../utils';
import {
	SectionWrapper,
	SkeletonUserSuggestionsList,
	UserSuggestionItem,
	SearchPreviewButton,
	SearchField,
	MenuButton,
} from './components';
import {
	SearchOptionType,
	SearchOptionsViewEnum,
	SearchType,
	SearchSuggestionUserType,
	ChatType,
} from '../../types';

//! Remove logs before release push

const years = getYearsUntilNow(2023);

interface ISearchPopup {
	defaultMainKeywords: string[];
	defaultSubKeywords: Record<string, string[]>;
	searchPopupOptionsState: Record<string, SearchOptionsViewEnum>;
	mainSearchOptionsMenu: SearchOptionType<SearchOptionsViewEnum>[];
	chatType: ChatType;
	staticSuggestions?: SearchSuggestionUserType[];
}

export const SearchPopup: React.FC<ISearchPopup> = ({
	defaultMainKeywords,
	searchPopupOptionsState,
	mainSearchOptionsMenu,
	defaultSubKeywords,
	chatType,
	staticSuggestions = [],
}) => {
	const { id } = useParams();

	const {
		isOpenSearchResultSidebar,
		isOpenMembersSidebar,
		isOpenDirectFriendInfoSidebar,
		onOpenSearchResultSidebar,
		onCloseSearchResultSidebar,
		onOpenMembersSidebar,
		onOpenDirectFriendInfoSidebar,
	} = useMessengerSidebarsOpenStore();
	const { onUpdateQueries } = useChannelSearchStore();

	const [search, setSearch] = useState('');

	const inputRef = useRef<HTMLInputElement>(null);
	const popoverContentRef = useRef<HTMLDivElement | null>(null);
	// const currentOptionsRef = useRef<HTMLButtonElement[]>([]);

	const [optionsView, setOptionsView] = useState(SearchOptionsViewEnum.None);
	const [subKeywords, setSubKeywords] = useState(defaultSubKeywords);

	// const addToOptionsRef = (el: HTMLButtonElement, index: number) => {
	// 	currentOptionsRef.current[index] = el;
	// };

	const debounceSearch = useDynamicDebounce(search, 500, () => {
		// const keys = ['user: email', 'user: name', 'user: username'];
		// const match = keys.find(key => {
		// 	const regex = new RegExp(`${key}\\s*[^\\s]`);
		// 	return regex.test(search);
		// });
		// if (!match) {
		// 	// onUpdateQueries({
		// 	// 	isEnabledSearchUsers: true,
		// 	// });
		// 	return;
		// }
		// const remainingString = search.replace(match, '').trim();
		// console.log('Remaining string:', remainingString);
		// console.log('match', match);
		// // Optionally split the remaining string by space (or another separator)
		// const splitString = remainingString.split(/\s+/).join('');
		// onUpdateQueries({
		// 	search: splitString,
		// 	isEnabledSearchUsers: true,
		// });
	});

	const isChannel = useMemo(() => chatType === ChatType.CHANNEL, [chatType]);
	const suggestionSearchQuery = useMemo(() => {
		if (optionsView === SearchOptionsViewEnum.AllCategories) {
			return debounceSearch;
		}

		if (
			optionsView === SearchOptionsViewEnum.From ||
			optionsView === SearchOptionsViewEnum.Mentions
		) {
			return debounceSearch
				.toLowerCase()
				.split(`${optionsView}:`)[1]
				?.trim();
		}

		return;
	}, [optionsView, debounceSearch]);

	const isSearchSuggestionsEnabled = useMemo(() => {
		const { From, Mentions, AllCategories } = SearchOptionsViewEnum;
		const searchSuggestionOptions = [From, Mentions, AllCategories];
		return (
			searchSuggestionOptions.includes(optionsView) &&
			!!id &&
			!!debounceSearch.length &&
			isChannel
		);
	}, [optionsView, id, debounceSearch, isChannel]);

	const { data: suggestionsUsersData, isLoading: isLoadingSuggestionsUsers } =
		useGetChannelSearchSuggestions({
			channelId: id ? +id : 0,
			isEnabled: isSearchSuggestionsEnabled,
			pageSize:
				optionsView === SearchOptionsViewEnum.From ||
				optionsView === SearchOptionsViewEnum.Mentions
					? 10
					: 3,
			search: suggestionSearchQuery,
		});

	const isOpen = useMemo(
		() => optionsView !== SearchOptionsViewEnum.None,
		[optionsView],
	);
	const isDuringType = useMemo(
		() => optionsView === SearchOptionsViewEnum.During,
		[optionsView],
	);

	const filteredStaticSuggestions = useMemo(() => {
		if (optionsView === SearchOptionsViewEnum.AllCategories) {
			return staticSuggestions.filter(
				user =>
					user.displayName
						.toLowerCase()
						.includes(search.toLowerCase()) ||
					user.nickName.toLowerCase().includes(search.toLowerCase()),
			);
		}

		if (
			optionsView === SearchOptionsViewEnum.From ||
			optionsView === SearchOptionsViewEnum.Mentions
		) {
			const trimmedSearch = search.split(`${optionsView}:`)[1]?.trim();
			return staticSuggestions.filter(
				user =>
					user.displayName
						.toLowerCase()
						.includes(trimmedSearch?.toLowerCase() || '') ||
					user.nickName
						.toLowerCase()
						.includes(trimmedSearch?.toLowerCase() || ''),
			);
		}

		return staticSuggestions;
	}, [optionsView, search, staticSuggestions]);

	const focusInput = () => {
		if (!inputRef.current) {
			return;
		}
		inputRef.current.focus();
	};
	const blurInput = () => {
		setTimeout(() => {
			inputRef.current?.blur();
		}, 0);
	};

	// const handleInputKeyDown = (
	// 	event: React.KeyboardEvent<HTMLInputElement>,
	// ) => {
	// 	if (event.key === 'ArrowDown') {
	// 		setSelectedIndex(prevIndex =>
	// 			prevIndex < options.length - 1 ? prevIndex + 1 : 0,
	// 		);
	// 		event.preventDefault();
	// 	} else if (event.key === 'ArrowUp') {
	// 		setSelectedIndex(prevIndex =>
	// 			prevIndex > 0 ? prevIndex - 1 : options.length - 1,
	// 		);
	// 		event.preventDefault();
	// 	}
	// };

	const updateOptionsView = (searchValue: string) => {
		if (!searchValue) {
			setOptionsView(SearchOptionsViewEnum.Default);
			return;
		}

		const searchType = searchValue.trim().split(':')[0].toLowerCase();

		if (searchPopupOptionsState[searchType]) {
			setOptionsView(searchPopupOptionsState[searchType]);
			return;
		}

		setOptionsView(SearchOptionsViewEnum.AllCategories);
	};

	const filterByLabels = (
		data: { label: string; value: number }[],
		searchString: string,
	) => {
		if (!searchString) {
			return data;
		}

		const escapedString = searchString.replace(
			/[.*+?^${}()|[\]\\]/g,
			'\\$&',
		);
		if (!escapedString) {
			return data;
		}

		const pattern = escapedString.split('').join('.*');
		if (pattern === '.*') {
			return data;
		}

		try {
			const regex = new RegExp(pattern, 'i');
			return data.filter(item => regex.test(item.label));
		} catch (e) {
			return data;
		}
	};

	const extractSubMenuKeywordAndQuery = <T extends number | boolean>(
		input: string,
		keywords: SearchOptionType<T>[],
	): { keyword: SearchOptionType<T> | null; searchQuery: string | null } => {
		// regex to match the keywords
		const keywordsLabels = keywords.map(k => k.label).join('|');

		const regex = new RegExp(`\\b(${keywordsLabels})\\b(.*)`, 'i');

		// remove prefix
		const prefixRemoved = input.replace(/.*?:\s*/, '').trim();
		const match = prefixRemoved.match(regex);

		if (!match) {
			return {
				keyword: null,
				searchQuery: prefixRemoved || null,
			};
		}

		const foundKeyword = keywords.find(
			k => k.label.toLowerCase() === match[1].toLowerCase(),
		);

		return {
			keyword: foundKeyword || null,
			searchQuery: match[2].trim() || null,
		};
	};

	const extractUserAndQuery = (
		input: string,
		suggestions: SearchSuggestionUserType[],
	): {
		user: SearchSuggestionUserType | null;
		searchQuery: string | null;
	} => {
		// regex to match the keywords
		const nicknames = suggestions.map(user => user.nickName).join('|');

		const regex = new RegExp(`\\b(${nicknames})\\b(.*)`, 'i');

		// remove prefix
		const prefixRemoved = input.replace(/.*?:\s*/, '').trim();
		const match = prefixRemoved.match(regex);

		if (!match) {
			return {
				user: null,
				searchQuery: prefixRemoved || null,
			};
		}

		const foundUser = suggestions.find(
			user => user.nickName.toLowerCase() === match[1].toLowerCase(),
		);

		return {
			user: foundUser || null,
			searchQuery: match[2].trim() || null,
		};
	};

	const extractDateAndQuery = (
		input: string,
	): { date?: string; searchQuery: string | null } => {
		const parts = input.split(/\s+/);
		const date = parts.find(part => isValidDate(part));
		const searchQuery = date
			? parts.filter(part => part !== date).join(' ')
			: null;

		return { date, searchQuery };
	};

	const openSidebarBySearchType = useCallback(
		(searchType: SearchType) => {
			switch (searchType) {
				case SearchType.Users:
					if (!isOpenMembersSidebar && isChannel) {
						onOpenMembersSidebar();
					}
					if (!isOpenDirectFriendInfoSidebar && !isChannel) {
						onOpenDirectFriendInfoSidebar();
					}
					break;
				case SearchType.Messages:
					if (!isOpenSearchResultSidebar) {
						onOpenSearchResultSidebar(isChannel);
					}
					break;
				default:
					break;
			}
		},
		[
			isChannel,
			isOpenMembersSidebar,
			isOpenDirectFriendInfoSidebar,
			isOpenSearchResultSidebar,
		],
	);

	const onEndTyping = (searchType: SearchType): void => {
		blurInput();
		openSidebarBySearchType(searchType);
		setOptionsView(SearchOptionsViewEnum.None);
	};

	const handleUserMessagesSearch = (
		user: SearchSuggestionUserType | null,
		searchQuery: string | null,
		isMentions: boolean,
	) => {
		if (user) {
			setSubKeywords(prev => ({
				...prev,
				[SearchOptionsViewEnum.From]: [user.nickName],
				[SearchOptionsViewEnum.Mentions]: [user.nickName],
			}));
		}
		onUpdateQueries({
			search: searchQuery || '',
			authorId: isMentions ? undefined : user?.id || undefined,
			mentionId: isMentions ? user?.id || undefined : undefined,
			isEnabledSearchMessages: true,
			isEnabledSearchUsers: false,
		});
	};

	const onEnterSearchField = useCallback(() => {
		if (!search) {
			return;
		}

		const searchType = search.trim().split(':')[0].toLowerCase();

		switch (searchPopupOptionsState[searchType]) {
			case SearchOptionsViewEnum.From:
			case SearchOptionsViewEnum.Mentions: {
				const { user, searchQuery } = extractUserAndQuery(
					search,
					isChannel
						? suggestionsUsersData?.value?.users || []
						: staticSuggestions,
				);
				console.log('from or mentions: ', user, user?.id, searchQuery);
				onEndTyping(SearchType.Messages);

				const isMentions =
					searchPopupOptionsState[searchType] ===
					SearchOptionsViewEnum.Mentions;

				handleUserMessagesSearch(user, searchQuery, isMentions);

				return;
			}
			case SearchOptionsViewEnum.Has: {
				const { keyword, searchQuery } = extractSubMenuKeywordAndQuery(
					search,
					HasSubMenuSearchOptions,
				);
				console.log('has: ', keyword?.value, searchQuery);
				onEndTyping(SearchType.Messages);
				onUpdateQueries({
					search: searchQuery || '',
					contentType: keyword?.value || undefined,
					isEnabledSearchMessages: true,
					isEnabledSearchUsers: false,
				});

				return;
			}
			case SearchOptionsViewEnum.During: {
				const dateString = search.split('during:')[1]?.trim();
				if (dateString) {
					const { date, searchQuery } =
						extractDateAndQuery(dateString);

					const dateRegex =
						/^(0[1-9]|1[0-2])\.(0[1-9]|[12][0-9]|3[01])\.(\d{4})$/;

					if (date && dateRegex.test(date)) {
						const parsedDate = dayjs(date, 'MM.DD.YYYY').format(
							'MM.DD.YYYY',
						);
						const { startDate, endDate } = getISODateRange(
							parsedDate,
							'day',
						);
						console.log(
							'during day: ',
							startDate,
							endDate,
							dateString,
						);
						onEndTyping(SearchType.Messages);
						setSubKeywords(prev => ({
							...prev,
							[SearchOptionsViewEnum.During]: [
								...prev[SearchOptionsViewEnum.During],
								date,
							],
						}));
						onUpdateQueries({
							search: searchQuery || '',
							toDateTime: endDate,
							fromDateTime: startDate,
							isEnabledSearchMessages: true,
							isEnabledSearchUsers: false,
						});
						return;
					}

					const monthResult = extractSubMenuKeywordAndQuery(
						search,
						MonthsInDateSection,
					);
					if (monthResult.keyword?.value) {
						const { startDate, endDate } = getISODateRange(
							monthResult.keyword.value,
							'month',
						);
						console.log(
							'during month: ',
							startDate,
							endDate,
							monthResult.searchQuery,
						);
						onEndTyping(SearchType.Messages);
						onUpdateQueries({
							search: monthResult.searchQuery || '',
							toDateTime: endDate,
							fromDateTime: startDate,
							isEnabledSearchMessages: true,
							isEnabledSearchUsers: false,
						});
						return;
					}

					const yearResult = extractSubMenuKeywordAndQuery(
						search,
						years,
					);
					if (yearResult.keyword?.value) {
						const { startDate, endDate } = getISODateRange(
							yearResult.keyword.value,
							'year',
						);
						console.log(
							'during year: ',
							startDate,
							endDate,
							yearResult.searchQuery,
						);
						onEndTyping(SearchType.Messages);
						onUpdateQueries({
							search: yearResult.searchQuery || '',
							toDateTime: endDate,
							fromDateTime: startDate,
							isEnabledSearchMessages: true,
							isEnabledSearchUsers: false,
						});
						return;
					}

					onEndTyping(SearchType.Messages);
					onUpdateQueries({
						search: dateString || '',
						isEnabledSearchMessages: true,
						isEnabledSearchUsers: false,
					});
					return;
				}
				return;
			}
			case SearchOptionsViewEnum.Pinned: {
				const { keyword, searchQuery } = extractSubMenuKeywordAndQuery(
					search,
					PinnedSubMenuSearchOptions,
				);
				console.log('pinned: ', keyword?.value, searchQuery);
				onEndTyping(SearchType.Messages);
				onUpdateQueries({
					search: searchQuery || '',
					pinned: keyword?.value || undefined,
					isEnabledSearchMessages: true,
					isEnabledSearchUsers: false,
				});
				return;
			}

			case SearchOptionsViewEnum.User: {
				const { keyword, searchQuery } = extractSubMenuKeywordAndQuery(
					search,
					UserSubMenuSearchOptions,
				);
				console.log(
					'onEnterSearchField user: ',
					keyword?.value,
					searchQuery,
				);
				onEndTyping(SearchType.Users);
				onUpdateQueries({
					search: searchQuery || '',
					userFilter: keyword?.value || undefined,
					isEnabledSearchUsers: true,
				});
				return;
			}

			default:
				onUpdateQueries({
					search,
					isEnabledSearchUsers: false,
					isEnabledSearchMessages: true,
				});
				onEndTyping(SearchType.Messages);
				return;
		}
	}, [search]);

	const getOptionsMenu = (type: SearchOptionsViewEnum) => {
		switch (type) {
			case SearchOptionsViewEnum.Default:
				return (
					<SectionWrapper title="Search Options">
						{mainSearchOptionsMenu.map((el, index) => (
							<MenuButton
								key={index}
								// ref={el =>
								// 	addToOptionsRef(
								// 		el as HTMLButtonElement,
								// 		index,
								// 	)
								// }
								highlightedLabel={el.value}
								label={el.label}
								buttonProps={{
									onClick: () => {
										setSearch(`${el.value}: `);
										setOptionsView(el.value);
										focusInput();
									},
								}}
							/>
						))}
					</SectionWrapper>
				);

			case SearchOptionsViewEnum.From: {
				const suggestions = isChannel
					? suggestionsUsersData?.value?.users || []
					: filteredStaticSuggestions;
				return (
					<React.Fragment>
						{isLoadingSuggestionsUsers ? (
							<SectionWrapper title="From User">
								<SkeletonUserSuggestionsList length={5} />
							</SectionWrapper>
						) : null}
						{!isLoadingSuggestionsUsers && suggestions.length ? (
							<SectionWrapper title="From User">
								{suggestions.map(el => (
									<UserSuggestionItem
										key={el.id}
										avatarUrl={el.avatarUrl}
										name={el.displayName}
										username={el.nickName}
										onClick={() => {
											setSubKeywords(prev => ({
												...prev,
												[SearchOptionsViewEnum.From]: [
													el.nickName,
												],
											}));
											setSearch(`from: ${el.nickName}`);
											onEndTyping(SearchType.Messages);
											onUpdateQueries({
												authorId: el.id,
												isEnabledSearchUsers: false,
												isEnabledSearchMessages: true,
											});
										}}
									/>
								))}
							</SectionWrapper>
						) : null}
					</React.Fragment>
				);
			}
			case SearchOptionsViewEnum.Mentions: {
				const suggestions = isChannel
					? suggestionsUsersData?.value?.users || []
					: filteredStaticSuggestions;
				return (
					<React.Fragment>
						{isLoadingSuggestionsUsers ? (
							<SectionWrapper title="Mentions User">
								<SkeletonUserSuggestionsList length={5} />
							</SectionWrapper>
						) : null}
						{!isLoadingSuggestionsUsers && suggestions.length ? (
							<SectionWrapper title="Mentions User">
								{suggestions.map(el => (
									<UserSuggestionItem
										key={el.id}
										avatarUrl={el.avatarUrl}
										name={el.displayName}
										username={el.nickName}
										onClick={() => {
											setSearch(
												`mentions: ${el.nickName}`,
											);
											setSubKeywords(prev => ({
												...prev,
												[SearchOptionsViewEnum.Mentions]:
													[el.nickName],
											}));
											onEndTyping(SearchType.Messages);
											onUpdateQueries({
												mentionId: el.id,
												isEnabledSearchUsers: false,
												isEnabledSearchMessages: true,
											});
										}}
									/>
								))}
							</SectionWrapper>
						) : null}
					</React.Fragment>
				);
			}

			case SearchOptionsViewEnum.Has: {
				const filteredOptions = filterByLabels(
					HasSubMenuSearchOptions,
					search.split('has: ')[1],
				);
				return filteredOptions.length ? (
					<SectionWrapper title="Message Contains">
						{filteredOptions.map((el, index) => (
							<MenuButton
								key={index}
								label={el.label}
								buttonProps={{
									onClick: () => {
										setSearch(`has: ${el.label}`);
										onUpdateQueries({
											contentType: el.value,
											isEnabledSearchUsers: false,
											isEnabledSearchMessages: true,
										});
										onEndTyping(SearchType.Messages);
									},
								}}
							/>
						))}
					</SectionWrapper>
				) : null;
			}

			case SearchOptionsViewEnum.During:
				return (
					<Box p="8px 16px 10px 16px" bg="white" borderRadius="4px">
						<InlineDatePicker
							maxDate={new Date()}
							onSetValue={date => {
								if (!date) {
									return;
								}
								const formattedDate =
									dayjs(date).format('MM.DD.YYYY');
								setSearch(`during: ${formattedDate}`);
								const { startDate, endDate } = getISODateRange(
									date,
									'day',
								);
								setSubKeywords(prev => ({
									...prev,
									[SearchOptionsViewEnum.During]: [
										...prev[SearchOptionsViewEnum.During],
										formattedDate,
									],
								}));
								onUpdateQueries({
									fromDateTime: startDate,
									toDateTime: endDate,
									isEnabledSearchUsers: false,
									isEnabledSearchMessages: true,
								});
								onEndTyping(SearchType.Messages);
							}}
						/>
					</Box>
				);
			case SearchOptionsViewEnum.Pinned:
				return (
					<SectionWrapper>
						{PinnedSubMenuSearchOptions.map((el, index) => (
							<MenuButton
								key={index}
								label={el.label}
								buttonProps={{
									onClick: () => {
										setSearch(`pinned: ${el.label}`);
										onUpdateQueries({
											pinned: el.value,
											isEnabledSearchUsers: false,
											isEnabledSearchMessages: true,
										});
										onEndTyping(SearchType.Messages);
									},
								}}
							/>
						))}
					</SectionWrapper>
				);

			case SearchOptionsViewEnum.User: {
				const filteredOptions = filterByLabels(
					UserSubMenuSearchOptions,
					search.split('user:')[1]?.trim(),
				);
				return filteredOptions.length ? (
					<SectionWrapper>
						{filteredOptions.map((el, index) => (
							<MenuButton
								key={index}
								label={el.label}
								buttonProps={{
									onClick: () => {
										setSearch(`user: ${el.label} `);
										onUpdateQueries({
											isEnabledSearchUsers: true,
										});
										setOptionsView(
											SearchOptionsViewEnum.None,
										);

										openSidebarBySearchType(
											SearchType.Users,
										);
										focusInput();
									},
								}}
							/>
						))}
					</SectionWrapper>
				) : null;
			}
			case SearchOptionsViewEnum.AllCategories: {
				const filteredMessageContains = filterByLabels(
					HasSubMenuSearchOptions,
					search,
				);
				const filteredDates = filterByLabels(
					isNaN(Number(search)) ? MonthsInDateSection : years,
					search,
				);
				const suggestions = isChannel
					? suggestionsUsersData?.value?.users || []
					: filteredStaticSuggestions;
				return (
					<VStack
						bg="blue.200"
						maxH="calc(100vh - 300px)"
						className="custom-scroll3"
						overflowY="auto"
						overflowX="hidden">
						<VStack
							spacing={0}
							align="flex-start"
							w="full"
							divider={
								<Divider
									borderColor="blue.100"
									opacity={1}
									textAlign="center"
								/>
							}>
							{isLoadingSuggestionsUsers ? (
								<SectionWrapper title="From User">
									<SkeletonUserSuggestionsList length={3} />
								</SectionWrapper>
							) : null}
							{!isLoadingSuggestionsUsers &&
							suggestions.length ? (
								<SectionWrapper title="From User">
									{suggestions.map(el => (
										<UserSuggestionItem
											key={el.id}
											avatarUrl={el.avatarUrl}
											name={el.displayName}
											username={el.nickName}
											onClick={() => {
												setSearch(
													`from: ${el.nickName}`,
												);
												onUpdateQueries({
													authorId: el.id,
													isEnabledSearchMessages:
														true,
													isEnabledSearchUsers: false,
												});
												onEndTyping(
													SearchType.Messages,
												);
											}}
										/>
									))}
								</SectionWrapper>
							) : null}
							{isLoadingSuggestionsUsers ? (
								<SectionWrapper title="Mentions">
									<SkeletonUserSuggestionsList length={3} />
								</SectionWrapper>
							) : null}
							{!isLoadingSuggestionsUsers &&
							suggestionsUsersData?.value?.users.length ? (
								<SectionWrapper title="Mentions">
									{suggestionsUsersData.value.users.map(
										el => (
											<UserSuggestionItem
												key={el.id}
												avatarUrl={el.avatarUrl}
												name={el.displayName}
												username={el.nickName}
												onClick={() => {
													setSearch(
														`mentions: ${el.nickName}`,
													);
													onUpdateQueries({
														mentionId: el.id,
														isEnabledSearchMessages:
															true,
														isEnabledSearchUsers:
															false,
													});
													onEndTyping(
														SearchType.Messages,
													);
												}}
											/>
										),
									)}
								</SectionWrapper>
							) : null}
							{filteredMessageContains.length ? (
								<SectionWrapper title="Message Contains">
									{filteredMessageContains.map(
										(el, index) => (
											<MenuButton
												key={index}
												label={el.label}
												highlightedLabel="has"
												buttonProps={{
													onClick: () => {
														setSearch(
															`has: ${el.label}`,
														);
														onUpdateQueries({
															contentType:
																el.value,
															isEnabledSearchMessages:
																true,
															isEnabledSearchUsers:
																false,
														});
														onEndTyping(
															SearchType.Messages,
														);
													},
												}}
											/>
										),
									)}
								</SectionWrapper>
							) : null}
							{filteredDates.length ? (
								<SectionWrapper title="Dates">
									{filteredDates
										.slice(0, 1)
										.map((el, index) => (
											<MenuButton
												key={index}
												label={el.label}
												highlightedLabel="during"
												buttonProps={{
													onClick: () => {
														const {
															startDate,
															endDate,
														} = getISODateRange(
															el.value,
															isNaN(
																Number(search),
															)
																? 'month'
																: 'year',
														);
														onUpdateQueries({
															fromDateTime:
																startDate,
															toDateTime: endDate,
															isEnabledSearchMessages:
																true,
															isEnabledSearchUsers:
																false,
														});
														setSearch(
															`during: ${el.label}`,
														);
														onEndTyping(
															SearchType.Messages,
														);
													},
												}}
											/>
										))}
								</SectionWrapper>
							) : null}
						</VStack>
					</VStack>
				);
			}

			default:
				null;
		}
	};

	useOutsideClick({
		ref: popoverContentRef,
		handler: event => {
			if (
				inputRef.current?.contains(event.target as Node) ||
				inputRef.current?.parentNode?.contains(event.target as Node) ||
				popoverContentRef?.current?.contains(event.target as Node) ||
				!isOpen
			) {
				return;
			}

			blurInput();
			setOptionsView(SearchOptionsViewEnum.None);
			popoverContentRef.current?.blur();
		},
	});

	return (
		<Popover
			isLazy={true}
			placement="bottom-end"
			isOpen={isOpen}
			offset={[48, 4]}
			autoFocus={false}
			closeOnEsc={true}
			initialFocusRef={popoverContentRef}
			onClose={() => {
				//prevent onClose when clicking on empty search field
				if (!search || search) {
					return;
				}

				setOptionsView(SearchOptionsViewEnum.None);
				blurInput();
			}}
			onOpen={() => updateOptionsView(search)}>
			<PopoverTrigger>
				<SearchField
					mainKeywords={defaultMainKeywords}
					subKeywords={subKeywords[search.trim().split(':')[0]] || []}
					isFieldActive={isOpen || !!search.length}
					setValue={value => {
						setSearch(value);
						updateOptionsView(value);
					}}
					onEnter={onEnterSearchField}
					value={search}
					ref={inputRef}
					onClear={() => {
						setSearch('');
						if (inputRef.current) {
							inputRef.current.innerText = '';
						}
						blurInput();
						onCloseSearchResultSidebar(isChannel);
						setOptionsView(SearchOptionsViewEnum.None);
						onUpdateQueries({
							isEnabledSearchUsers: true,
						});
					}}
				/>
			</PopoverTrigger>
			<PopoverContent
				w={isDuringType ? 'auto' : '360px'}
				border="none"
				ref={popoverContentRef}>
				<PopoverBody p={0} borderRadius="4px" overflow="hidden">
					{optionsView === SearchOptionsViewEnum.AllCategories &&
					search.length ? (
						<SearchPreviewButton
							text={search}
							onClick={onEnterSearchField}
						/>
					) : null}
					{getOptionsMenu(optionsView)}
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};
