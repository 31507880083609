import React from 'react';
import { Box, Button, Text, chakra } from '@chakra-ui/react';

interface ISearchPreviewButton {
	text: string;
	onClick: () => void;
}

export const SearchPreviewButton: React.FC<ISearchPreviewButton> = ({
	text,
	onClick,
}) => {
	return (
		<Button
			variant="ghost"
			justifyContent="space-between"
			alignItems="center"
			w="full"
			borderRadius="0px"
			p={4}
			h="auto"
			bg="blue.200"
			columnGap={2}
			onClick={onClick}
			_hover={{
				bg: 'blue.200',
			}}
			_active={{
				bg: 'blue.200',
			}}>
			<Text
				color="white"
				fontSize="16px"
				lineHeight="20px"
				fontWeight={700}
				isTruncated={true}>
				<chakra.span textTransform="uppercase" fontWeight={500}>
					Search for:
				</chakra.span>{' '}
				{text}
			</Text>
			<Box bg="blue.100" borderRadius="4px" p="5px 10px">
				<Text
					textTransform="uppercase"
					fontSize="16px"
					lineHeight="20px"
					fontWeight={600}
					color="dropBlue">
					Enter
				</Text>
			</Box>
		</Button>
	);
};
