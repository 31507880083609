import React, { useEffect } from 'react';
import { Heading, VStack, Flex, Textarea, Button } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { FieldWrapper, CustomInput } from '../../../common-components';
import { EditChannelForm, editChannelResolver } from '../../../validation';
import { Announcement2Icon } from '../../../../../assets';
import { ServerChannelData } from '../../../types';
import { useEditChannel } from '../../../queries';

interface IOverviewTabProps {
	channel: ServerChannelData;
	onClose: VoidFunction;
}

export const OverviewTab: React.FC<IOverviewTabProps> = ({
	channel,
	onClose,
}) => {
	const { mutateAsync: editChannel, isPending: editChannelLoading } =
		useEditChannel();

	const {
		register,
		formState: { errors, isValid },
		handleSubmit,
		reset,
		control,
	} = useForm<EditChannelForm>({
		resolver: editChannelResolver,
	});

	useEffect(() => {
		resetForm();
	}, [channel]);

	const resetForm = () => {
		reset({
			name: channel.name,
			topic: channel.topic || '',
			// isPrivate: false,
		});
	};

	const onSubmit = async (data: EditChannelForm) => {
		try {
			const { name, topic } = data;

			await editChannel({
				channelId: channel.id,
				topic: topic || '',
				name,
			});
			onClose();
		} catch (error) {
			console.log(error, 'creating channel error');
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
			<Heading fontSize={'25px'} fontWeight={'700'} color={'white'}>
				Overview
			</Heading>
			<VStack spacing="20px" align="stretch" mt={'20px'}>
				<FieldWrapper title="CHANNEL NAME">
					<Flex gap="10px" align="center">
						<Announcement2Icon />
						<CustomInput
							{...register('name')}
							errorMsg={errors.name?.message}
						/>
					</Flex>
				</FieldWrapper>

				{/* <FieldWrapper title="CHANNEL TYPE">
					<Flex direction="column" gap="20px">
						<ServerRoomCreationMetaInfo
							name="Private"
							description="Only selected members and roles will be ables to view this channel"
							icon={<Lock2Icon />}
							rightElement={
								<Controller
									name="isPrivate"
									control={control}
									render={({ field }) => (
										<Switch
											size="sm"
											variant="smallOutlined"
											isChecked={field.value}
											onChange={ev =>
												field.onChange(
													ev.target.checked,
												)
											}
										/>
									)}
								/>
							}
						/>
					</Flex>
				</FieldWrapper> */}
				<FieldWrapper title="CHANNEL TOPIC">
					<Flex direction="column" gap="20px">
						<Textarea
							{...register('topic')}
							bg={'blue.200'}
							border={'none'}
							placeholder={'About'}
							color={'white'}
						/>
					</Flex>
				</FieldWrapper>

				<Flex gap="10px">
					<Button
						type="submit"
						height="40px"
						isDisabled={!isValid}
						isLoading={editChannelLoading}>
						Save Changes
					</Button>
					<Button
						colorScheme="blackAlpha"
						onClick={resetForm}
						bg="blue.100"
						height="40px">
						Reset
					</Button>
				</Flex>
			</VStack>
		</form>
	);
};
