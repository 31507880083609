/* eslint-disable quotes */

import React, { useImperativeHandle } from 'react';
import { Box, Flex, HStack, IconButton } from '@chakra-ui/react';
import { SearchIcon, XCloseIcon } from '../../../../../assets';
import { colors } from '../../../../../theme';
import { useSearchField } from '../../../hooks';

interface ISearchFieldProps {
	mainKeywords: string[];
	subKeywords: string[];
	isFieldActive: boolean;
	setValue: (value: string) => void;
	value: string;
	onClear: VoidFunction;
	onEnter: VoidFunction;
}

export const SearchField = React.forwardRef<HTMLDivElement, ISearchFieldProps>(
	(
		{
			mainKeywords,
			subKeywords,
			value,
			isFieldActive,
			setValue,
			onClear,
			onEnter,
			...props
		},
		ref,
	) => {
		const {
			inputRef,
			handleInput,
			handleKeyDown,
			handleFocus,
			handleBlur,
			handlePaste,
		} = useSearchField({
			value,
			mainKeywords,
			subKeywords,
			setValue,
			onEnter,
		});

		useImperativeHandle(ref, () => inputRef.current as HTMLDivElement);

		return (
			<HStack
				w={isFieldActive ? '360px' : '200px'}
				h="40px"
				bg="white"
				align="center"
				borderRadius="4px"
				transition="width 0.25s ease"
				position="relative"
				onClick={event => {
					event.stopPropagation();
					if (isFieldActive) {
						event.preventDefault();
					}
				}}>
				<Box
					ref={inputRef}
					contentEditable={true}
					flex={1}
					mx="4px"
					py="4px"
					h="40px"
					lineHeight="32px"
					onInput={handleInput}
					onKeyDown={handleKeyDown}
					onClick={event => {
						event.stopPropagation();
						if (isFieldActive) {
							event.preventDefault();
						}
					}}
					onFocus={handleFocus}
					onBlur={handleBlur}
					onPaste={handlePaste}
					outline="none"
					noOfLines={1}
					overflowX="auto"
					whiteSpace="nowrap"
					sx={{
						'&::-webkit-scrollbar': {
							display: 'none',
						},
						'scrollbar-width': 'none',
					}}
					_before={{
						content: value.length ? `''` : `'Search'`,
						position: 'absolute',
						top: '8px',
						left: '4px',
						color: 'inactiveText',
						fontSize: '16px',
						lineHeight: '24px',
						cursor: 'text',
					}}
					_placeholder={{ color: 'inactiveText', fontSize: '14px' }}
					_focusVisible={{
						borderColor: 'none',
						boxShadow: 'unset',
					}}
					{...props}
				/>
				{value.trim().length ? (
					<IconButton
						aria-label="Clear search field"
						variant="ghost"
						bg="transparent"
						w="36px"
						minW="36px"
						icon={<XCloseIcon />}
						onClick={event => {
							event.preventDefault();
							onClear();
						}}
						_hover={{ bg: 'transparent' }}
						_active={{ bg: 'transparent' }}
					/>
				) : (
					<Flex px="8px" h="full" align="center" w="36px">
						<SearchIcon size="20px" color={colors.blue[200]} />
					</Flex>
				)}
			</HStack>
		);
	},
);

SearchField.displayName = 'SearchField';
