import { useQuery } from '@tanstack/react-query';
import { MessengerQueryKeys } from './query-keys';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponse } from '../../../types';
import { ConversationHistoryItem } from '../types';

export const useFindConversationMessage = (
	messageId: string | undefined | null,
	conversationId: number,
) => {
	return useQuery<
		ApiResponse<{
			messages: ConversationHistoryItem[];
			userLastReadDateTime: string;
			unreadCount: number;
		}>
	>({
		queryKey: [
			MessengerQueryKeys.FIND_CONVERSATION_MESSAGE,
			messageId,
			conversationId,
		],
		queryFn: () =>
			API_CHAT.get(
				`/api/app/conversation/messages/private/${messageId}/find`,
				{
					ConversationId: conversationId,
					PageSize: 25,
				},
			),
		enabled: !!messageId,
	});
};
