import { useQuery } from '@tanstack/react-query';
import {
	getCurrentUserRequest,
	getCurrentUserRequestErrorHandling,
} from '../services';
import { DefaultGuestClaims } from '../types';
import { QueryKeys } from '../constants';

export const useGetCurrentUser = () => {
	return useQuery({
		queryKey: [QueryKeys.CURRENT_USER],
		queryFn: getCurrentUserRequest,
		staleTime: 36000000,
		select(data) {
			return {
				...data,
				claimsIds: data?.claimsIds || DefaultGuestClaims,
			};
		},
	});
};

export const useGetCurrentUserErrorHandling = () => {
	return useQuery({
		queryKey: [QueryKeys.CURRENT_USER_ERROR_HANDLING],
		queryFn: getCurrentUserRequestErrorHandling,
		staleTime: 36000000,
		select(data) {
			return {
				...data,
				value: {
					...data?.value,
					claimsIds: data?.value?.claimsIds || DefaultGuestClaims,
				},
			};
		},
	});
};
