import { useQuery } from '@tanstack/react-query';
import { API_CHAT } from '../../../api/api.base';
import { useConversationsList } from '../../../store';
import { ApiResponse } from '../../../types';
import { UserByIdData } from '../types';
import { updateConversationInList } from '../utils';
import { MessengerQueryKeys } from './query-keys';

export const useGetUserById = (
	userId: number | null,
	isFetchEnabled: boolean,
) => {
	const { setConversationsList } = useConversationsList();

	return useQuery<ApiResponse<UserByIdData>>({
		queryKey: [MessengerQueryKeys.GET_USER_BY_ID, userId],
		queryFn: () => API_CHAT.get(`/api/app/user/${userId}`, {}),
		// onSuccess: data => {
		// 	if (!data.success || !userId) {
		// 		return;
		// 	}

		// 	setConversationsList(prev => {
		// 		return prev.map(conversation => {
		// 			if (conversation.receiverId !== userId) {
		// 				return conversation;
		// 			}

		// 			return updateConversationInList(conversation, data.value);
		// 		});
		// 	});
		// },
		enabled: Boolean(userId) && isFetchEnabled,
	});
};
