import React, { useState } from 'react';
import {
	Avatar,
	Text,
	Flex,
	Skeleton,
	SkeletonCircle,
	Button,
} from '@chakra-ui/react';
import { ReplyVector } from '../../../../../assets';
import { ChatType, UserByIdData } from '../../../types';
import {
	useGetChannelMessageById,
	useGetPrivateMessageById,
} from '../../../queries';
import { UserInfoPopupWrapper } from './UserInfoPopupWrapper';
import { MessageContent } from './MessageContent';
import { SUPER_USER_ID } from '../../../../../constants';

interface IMessageReplyProps {
	replyToId?: number | null;
	chatType: ChatType;
	isMessageJumpEnabled: boolean;
	receiver?: UserByIdData | null;
	includeUserInfoPopup?: boolean;
	isChatListFlow: boolean;
	onPress?: VoidFunction;
}

export const MessageReply: React.FC<IMessageReplyProps> = ({
	replyToId,
	chatType,
	receiver,
	includeUserInfoPopup,
	isMessageJumpEnabled,
	isChatListFlow,
	onPress,
}) => {
	const [userInfoId, setUserInfoId] = useState<number | null>(null);

	const { data: privateMessageData, isLoading: privateMessageLoading } =
		useGetPrivateMessageById(
			chatType === ChatType.DIRECT ? replyToId : null,
		);

	const { data: channelMessageData, isLoading: channelMessageLoading } =
		useGetChannelMessageById(
			chatType === ChatType.CHANNEL ? replyToId : null,
		);
	const replyMessageData = privateMessageData || channelMessageData;

	const isSuperUserOnServer =
		SUPER_USER_ID === replyMessageData?.value?.sender?.userId &&
		chatType === ChatType.CHANNEL;

	return (
		<Flex align="center" gap="5px" pl="25px" mb="10px">
			<Flex
				pt="5px"
				h="24px"
				align="center"
				role={
					isMessageJumpEnabled && isChatListFlow && !!replyToId
						? 'button'
						: undefined
				}
				onClick={() => {
					if (!isChatListFlow || !replyToId) {
						return;
					}
					onPress?.();
				}}
				_hover={{
					'> svg > path': {
						stroke:
							isChatListFlow && !!replyToId
								? 'blue.300'
								: 'dropBlue',
					},
					'+ div > .reply__content': {
						bg:
							isChatListFlow && !!replyToId
								? 'gray.200'
								: 'transparent',
					},
				}}
				sx={{
					'> svg > path': {
						transition: 'stroke .2s',
					},
					'&:has(+ div > .reply__content:hover) > svg > path': {
						stroke:
							isChatListFlow && !!replyToId
								? 'blue.300'
								: 'dropBlue',
					},
				}}>
				<ReplyVector />
			</Flex>
			{privateMessageLoading || channelMessageLoading ? (
				<Flex align="center" gap="5px">
					<SkeletonCircle size="20px" />
					<Skeleton height="21px" width="200px" />
				</Flex>
			) : (
				<>
					{replyMessageData ? (
						<Flex align="center" gap="5px" fontSize="14px">
							<UserInfoPopupWrapper
								userInfoId={userInfoId}
								receiver={receiver}
								chatType={chatType}>
								<Button
									variant="ghost"
									w="20px"
									h="20px"
									minW="20px"
									px="0"
									cursor={
										includeUserInfoPopup
											? 'pointer'
											: 'default'
									}
									_hover={{}}
									_active={{}}
									onClick={event => {
										if (
											!includeUserInfoPopup ||
											!replyMessageData?.value?.sender
												?.userId
										) {
											event.preventDefault();
											return;
										}

										setUserInfoId(
											replyMessageData.value.sender
												.userId,
										);
									}}>
									<Avatar
										boxSize="20px"
										src={
											replyMessageData.value?.sender
												.avatarStoragePath || ''
										}
									/>
								</Button>
							</UserInfoPopupWrapper>
							<UserInfoPopupWrapper
								userInfoId={userInfoId}
								receiver={receiver}
								chatType={chatType}>
								<Button
									variant="ghost"
									px="0"
									h="auto"
									cursor={
										includeUserInfoPopup
											? 'pointer'
											: 'default'
									}
									_hover={{
										textDecoration:
											!includeUserInfoPopup ||
											!replyMessageData?.value?.sender
												?.userId
												? 'none'
												: 'underline',
									}}
									_active={{}}
									onClick={event => {
										if (
											!includeUserInfoPopup ||
											!replyMessageData?.value?.sender
												?.userId
										) {
											event.preventDefault();
											return;
										}

										setUserInfoId(
											replyMessageData.value.sender
												.userId,
										);
									}}>
									<Text
										fontSize={
											isSuperUserOnServer
												? '12px'
												: '14px'
										}
										lineHeight="17px"
										color={
											isSuperUserOnServer
												? 'white'
												: 'blue.50'
										}
										backgroundColor={
											isSuperUserOnServer
												? 'inactiveBlue'
												: 'transparent'
										}
										borderRadius={
											isSuperUserOnServer
												? '5px'
												: 'unset'
										}
										px={isSuperUserOnServer ? '4px' : '0px'}
										fontWeight={700}>
										{replyMessageData.value?.sender
											?.displayName || ''}
									</Text>
								</Button>
							</UserInfoPopupWrapper>
							<Button
								height="auto"
								px={1}
								minW="auto"
								bg="transparent"
								className={
									isMessageJumpEnabled
										? 'reply__content'
										: undefined
								}
								cursor={
									isMessageJumpEnabled ? 'pointer' : 'default'
								}
								onClick={onPress}
								whiteSpace="normal"
								_hover={{
									bg: isMessageJumpEnabled
										? 'gray.200'
										: 'transparent',
								}}>
								<MessageContent
									text={
										replyMessageData.value?.message?.text ||
										''
									}
									numberOfLines={1}
									enableBreaks={false}
									containerStyles={{
										a: {
											pointerEvents: 'none',
										},
										'.spoiler-content': {
											pointerEvents: 'none',
											position: 'relative',
											zIndex: 10,
											backgroundColor: 'bgGray.200',
										},
									}}
								/>
							</Button>
						</Flex>
					) : (
						<Text color="blue.50">[Message deleted]</Text>
					)}
				</>
			)}
		</Flex>
	);
};
